import { main_blue2 } from "../../../config/Color";
import { Box, Button, Grid, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useHttp from "../../../hooks/useHttp";
import { USER_SIGNALLEMENT_QUERY_URL, USER_SIGNALLEMENT_URL } from "../../../config/RoutesUrl";
import useUser from "../../../hooks/useUser";
import useStatut from "../../../hooks/useStatut";
import dateToFrenchFormat from "../../../controller/dateToFrenchFormat";
// import FolderIcon from "../../../component/icons/FolderIcon";
import { SIGNALEMENT } from "config/PathUrl";
import Pagination from "@mui/material/Pagination";
import FolderIcon from '@mui/icons-material/Folder';
export default function SignalementTemplate(props) {
  const { labelle, length, status, searchValue } = props;
  const { getData, postData, putData, deleteData, getOneData } = useHttp(); // get data from server
  const { getUser } = useUser();
  const [signalement, setSignalement] = useState([]);

  const [rowsPerPage, setRowsPerPage] = useState(6);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const { label, color } = useStatut();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const onChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    searchValue === ""
      ? getData(USER_SIGNALLEMENT_QUERY_URL + "?user_id=" + getUser("user")?.user?.id + "&page=" + (page + 1) + "&per_page=" + rowsPerPage + "&statut=" + status + "&order_by=created_at&order=desc").then((data) => {
          setCount(data?.count);
          const sign = data?.data?.data?.map((item, index) => ({
            index: index + 1,
            id: item?.id,
            date: item?.date,
            code: item?.code,
            type_signalement: item?.type_signalement?.libelle,
            status: item?.statut,
            lieu: item?.localites,
            user: item?.user === null ? "Anonyme" : item?.user?.nom + " " + item?.user?.prenom,
          }));
          
          if (!data?.data?.error) {
            setSignalement(sign);
            setLoading(false);
          }
        })
      : getData(USER_SIGNALLEMENT_QUERY_URL + "?user_id=" + getUser("user")?.user?.id + "&page=" + (page + 1) + "&code=" + searchValue + "&per_page=" + rowsPerPage + "&statut=" + status + "&order_by=created_at&order=desc").then((data) => {
          setCount(data?.count);
          const sign = data?.data?.data?.map((item, index) => ({
            index: index + 1,
            id: item?.id,
            date: item?.date,
            code: item?.code,
            type_signalement: item?.type_signalement?.libelle,
            status: item?.statut,
            lieu: item?.localites,
            user: item?.user === null ? "Anonyme" : item?.user?.nom + " " + item?.user?.prenom,
          }));
          
          if (!data?.data?.error) {
            setSignalement(sign);
            setLoading(false);
          }
        });
  }, [getData, page, rowsPerPage, status, searchValue]);

  return (
    <Box sx={{ width: "100%" }}>
      <Stack direction={"column"} sx={{ width: "100%" }} spacing={2}>
        <Paper sx={{ width: "100%", position: "relative", p: 1, borderRadius: 4, border: "2px solid #8e9198", p: 2, ...(Array.isArray(signalement) && signalement.length === 0 && { height: "8rem" }) }}>
          <Stack direction={"row"} sx={{ width: "100%", height: "100%" }}>
            {Array.isArray(signalement) && signalement.length === 0 && (
              <Typography variant="body1" sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                Aucun signalement
              </Typography>
            )}
            <Grid container spacing={2}>
              {Array.isArray(signalement) &&
                signalement.map((items, index) => (
                  <Grid item xs={6} md={4} lg={2}>
                    <Stack direction={"column"}>
                      <Paper
                        elevation={1}
                        sx={{
                          bgcolor: "#fdde80",
                          borderRadius: "4px",
                          height: "5rem",
                          position: "relative",
                          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
                        }}
                      >
                        {/* <img src={folder_icon} alt="folder"  style={{ width: "100%", height: "5rem", objectFit: "contain", backgroundColor: "red" }} /> */}
                        
                        <FolderIcon style={{position: "absolute", top: 0, right: 0, color: "white" }} />
                      </Paper>
                      <Stack direction={"row"}>
                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                          {items?.type_signalement?.slice(0, 15)}
                        </Typography>
                        {items?.type_signalement?.length > 15 && (
                          <Typography variant="body1" sx={{ color: main_blue2 }}>
                            <Tooltip title={items?.type_signalement}> ... </Tooltip>
                          </Typography>
                        )}
                      </Stack>

                      <Stack direction={"column"} spacing={1}>
                        <Typography variant="body2"> {dateToFrenchFormat(items?.date)} </Typography>
                        <Typography variant="body2" sx={{ color: main_blue2 }}>
                          <Button onClick={() => navigate(SIGNALEMENT, { state: { id: items?.id, nextPage: true } })}>Voir Plus</Button>
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                ))}
            </Grid>
          </Stack>
          <Typography variant="body1" sx={{ position: "absolute", top: 0, left: { xs: "50%", md: "5%" }, transform: "translate(-50%, -50%)", bgcolor: "white", p: 0 }}>
            {labelle}
          </Typography>
        </Paper>
        <Pagination count={Math.ceil((count ?? 0) / (rowsPerPage ?? 6))} variant="outlined" shape="rounded" color="primary" onChange={onChangePage} page={page + 1} />
      </Stack>
    </Box>
  );
}
