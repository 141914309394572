import { Box, Button as ButtonMui, Container, Grid, Stack, Typography } from "@mui/material";

import KjosHeader from "component/KjosHeader";
import Footer from "component/footer/Footer";
import Rectangle from "component/rectangle/Rectangle";
import HeaderConnecte2 from "../../component/header/HeaderConnecte2";
import Header from "component/header/Header";
import useIsAuthenticate from "hooks/useIsAuthenticate";
import { useEffect, useState } from "react";
import useUser from "hooks/useUser";
import useHttp from "hooks/useHttp";
import { CMS_GET } from "config/RoutesUrl";

const content = <>
<Typography variant="h5" sx={{ textAlign: "center", fontWeight: "bold" }}> CONDITIONS D'UTILISATION DE L'APPLICATION DE SIGNALEMENT DE L’ANSSI</Typography>
<Typography variant="body1" sx={{ textAlign: "left" }}>
L'Introduction <br />
L'application de signalement des contenus illicite d’internet (ci-après "Vigilant") est un outil destiné à aider les citoyens à signaler les actes de cyber criminalité, cyber malveillance et divers incidents techniques relatives à la cyber sécurité. En utilisant cette application, vous acceptez les présentes conditions d'utilisation. Si vous n'êtes pas d'accord avec ces conditions, veuillez ne pas utiliser l'application.
Utilisation de l'application <br />
L'application ne doit être utilisée que dans le but de signaler les actes de signaler les actes de cyber criminalité, cyber malveillance et divers incidents techniques relatives à la cyber sécurité. Vous ne devez pas utiliser l'application à des fins illégales ou frauduleuses, ou d'une manière qui pourrait endommager l'application ou nuire à la confidentialité ou à la sécurité des informations contenues dans l'application.
Signalement de la corruption <br />
Tous les signalements doivent être faits de bonne foi et doivent être étayés par des preuves suffisantes. Vous êtes responsable de la véracité et de l'exactitude des informations que vous fournissez dans votre signalement. En signalant un acte, vous acceptez de coopérer avec les autorités compétentes chargées de l'enquête sur l'acte signalé.
Protection des données personnelles <br />
Nous nous engageons à protéger la vie privée et les données personnelles des utilisateurs de l'application. Les données personnelles collectées dans le cadre de l'utilisation de l'application seront traitées conformément à notre politique de confidentialité, disponible sur notre site web.
Propriété intellectuelle <br />
L'application et tout son contenu (textes, images, logos, etc.) sont protégés par des droits de propriété intellectuelle. Vous ne pouvez pas copier, reproduire, distribuer ou modifier le contenu de l'application sans notre autorisation écrite préalable. <br />
Limitation de responsabilité<br />
Nous ne sommes pas responsables des dommages ou pertes causés par l'utilisation de l'application, y compris les pertes de données ou les dommages causés par des virus ou d'autres logiciels malveillants. Nous ne sommes pas non plus responsables des actes de corruption signalés dans l'application.
Modification des conditions d'utilisation <br />
Nous nous réservons le droit de modifier les présentes conditions d'utilisation à tout moment. Les utilisateurs seront informés des changements par des notifications dans l'application. Si vous continuez à utiliser l'application après la modification des conditions d'utilisation, cela signifie que vous acceptez les nouvelles conditions.
Loi applicable et juridiction <br />
Les présentes conditions d'utilisation sont régies par la loi du pays où l'application est hébergée. Tout litige découlant de l'utilisation de l'application sera soumis à la juridiction compétente du pays où l'application est hébergée. <br />
En utilisant cette application, vous reconnaissez avoir lu et compris les présentes conditions d'utilisation et vous acceptez de les respecter. Si vous avez des questions ou des préoccupations concernant l'utilisation de l'application, veuillez nous contacter à l'adresse e-mail suivante : [adresse e-mail de l'application]. <br />
Dernière mise à jour : [16 octobre 2023]. <br />
   </Typography>
</>

export default function ConditionGeneral() {

  const {getUser} = useUser(); //will check if user is authenticated

  const { getData } = useHttp(); // get data from server
  const [cms, setcms] = useState({});
  useEffect(() => {
    getData(CMS_GET).then((data) => {
      if (data?.error) {
        return;
      }
      console.log("footer", data?.data?.footer)
      setcms(data?.data);
    });
  }, [])

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
      >
        <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>
          <KjosHeader>Condition générale d'utilsation | ANSSI</KjosHeader>

          {
            getUser("user") ? <HeaderConnecte2 /> : <Header />
          }

          <form style={{ width: "100%", height: "100%" }}>

          <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ height: "100%", width: "100%", p: 8 }} spacing={4}>

            <Rectangle  content={cms?.footer?.confidentialite_content ? <div dangerouslySetInnerHTML={{ __html: cms?.footer?.confidentialite_content }}></div> : content} />
          </Stack>
          </form>
        </Stack>
      </Box>
      <Footer fixed={false} />
    </>
  );
}
