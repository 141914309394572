import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function useActiveLink() {
  let disabled = true;
  let canUseLink = false;
  const pathname = useLocation().pathname;

  const mustBeDisabled = useCallback((links, linkGroup) => {

    Array.isArray(linkGroup) && linkGroup.forEach((link) => {

     if (pathname === link) {
      canUseLink = true;
      return
     }
   })

   console.log("canUseLink", canUseLink )


   if(!canUseLink) {
    return false;
   }

    if (Array.isArray(links)) {
      links.map((link, index) => {
         

         if (pathname === link) {
            disabled = false;
            return;
        }
      });

    } else {
      if (pathname !== links) {
            alert("ok");
            disabled = true;
      }
    }

    return disabled;
  }, []);

  useEffect(() => {
     return (() => {
       disabled = true;
       canUseLink = false;
     })
  })
  return {
    mustBeDisabled,
  }
}
