import { Box, Container, IconButton, Paper, Stack, SvgIcon, Typography, Button as ButtonMui } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import HeaderConnecte from "../../component/header/HeaderConnecte";
import SendIcon from "../../component/icons/SendIcon";
import KjosHeader from "component/KjosHeader";
import Footer from "component/footer/Footer";
import { PRE_PLAINTE_SERVICE_PATH, SIGNALEMENT_CREATE_PATH, SIGNALEMENT_SUIVIE_PATH } from "config/PathUrl";
export default function CreateFinSignalement() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
      >
                <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>

          <KjosHeader>Créer un signalement | ANSSI</KjosHeader>

          <HeaderConnecte />

          <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ height: "calc(60px + 100vh)", width: "100%", p: 8, position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", zIndex: 100000, bgcolor: "rgba(0, 0, 0, 0.5)"}}>
            <Paper elevation={10}>
              {/* X icon */}
              <Stack direction={"column"} sx={{ width: "100%", height: "100%" }}>
                <Stack direction={"row"} justifyContent={"flex-end"} sx={{ width: "100%" }}>
                  <IconButton onClick={() => navigate("/")}>
                    <CloseIcon />
                  </IconButton>
                </Stack>

                <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ height: "100%", width: "100%", p: 8 }} spacing={2}>
                  <SendIcon />

                  <Typography variant="h5" sx={{ textAlign: "center", fontWeight: "bold" }}>
                    Félicitations
                  </Typography>

                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    Votre signalement <br /> à été soumis avec succès <br /> Votre numéro de suivi est:
                  </Typography>

                  <br />
                  <Typography variant="body2" sx={{ textAlign: "center", background: "linear-gradient(95.08deg, rgba(33, 39, 89, 1) 0%, rgba(74, 144, 226, 1) 100%)", font: "800 24px sans-serif", textDecoration: "underline", "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent" }}>
                    {location?.state?.code}
                  </Typography>

                  <br />
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                  Souhaiter vous déposer une plainte pour ce signalement ?
                  </Typography>

                  <Stack direction={"row"} justifyContent={"flex-end"} spacing={3} sx={{ my: "auto" }}>
                    <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(SIGNALEMENT_SUIVIE_PATH)}>
                      Non
                    </ButtonMui>
                    <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(PRE_PLAINTE_SERVICE_PATH, { state: { signalement_id: location?.state?.code, nextPage: true } })}>
                      Oui
                    </ButtonMui>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </Box>
      <Footer />
    </>
  );
}
