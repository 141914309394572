import { Avatar, Box, Divider, IconButton, Menu, MenuItem, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import Logo from "../logo/Logo";
import Button from "../buttons/Button";
import { main_blue } from "../../config/Color";
import FinanceIcon from "../buttons/icons/FinanceIcon";
import LogoGuinee from "../logo/LogoGuinee";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PROFIL_PATH, SIGNALEMENT_CREATE_PATH, SIGNALEMENT_PRE_PLAINTE_PATH, SIGNALEMENT_SUIVIE_PATH, SIGNIN_PATH } from "../../config/PathUrl";
import useUser from "../../hooks/useUser";
import useDeconnect from "../../hooks/useDeconnect";
import getDefaultImage from "../../controller/getDefaultImage";
import { logout_icon, null_image, setting_icon } from "../../config/Image";
import { deepPurple } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import { CMS_GET } from "config/RoutesUrl";
import useHttp from "hooks/useHttp";

export default function HeaderConnecte2() {
  const { getUser } = useUser();
  const navigate = useNavigate();
  const { deconnect } = useDeconnect(); // deconnect user
  const location = useLocation();
  const pathname = location.pathname;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfile = () => {
    navigate(PROFIL_PATH, { state: { user: getUser("user")?.user, nextPage: true } });
  };

  const defaultSiteSlogan = (
    <>
     {/*  Portail officiel de signalement <br />
      des contenus illicites de l’internet */}
    </>
  );

  const { getData } = useHttp(); // get data from server
  const [cms, setcms] = useState({});
  useEffect(() => {
    getData(CMS_GET).then((data) => {
      if (data?.error) {
        return;
      }
      setcms(data?.data);
    });
  }, []);;

  return (
    <>
      <Box
        component="header"
        sx={{
          position: "sticky",
          top: 0,
          backgroundColor: "white",
          zIndex: 1000
        }}
      >
        <Stack direction="column" spacing={2}>
          {/* with Logo */}
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={6} sx={{ bgcolor: main_blue, color:"white" }}>
            <Stack direction={"row"} alignItems={"center"}>
              <Link to={"/"}>
              <Logo width="5rem" logo_url={getDefaultImage(cms?.header?.logo_url, null_image)} />
              </Link>
              <Typography sx={{ fontSize: { xs: "0.7rem", md: "1rem", lg: "1.2rem" }, ml: { lg: 2 } }}>{cms?.header?.site_slogan ? <div dangerouslySetInnerHTML={{ __html: cms?.header?.site_slogan.replace(/<\/?p>/g, '') }}></div> : defaultSiteSlogan}</Typography>
            </Stack>

            <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <React.Fragment>
                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                  <Tooltip title="Account settings">
                    <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }} aria-controls={open ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined}>
                      <Avatar sx={{ bgcolor: deepPurple[500] }} alt={getUser("user")?.user?.nom + " " + getUser("user")?.user?.prenom} src={getDefaultImage(getUser("user")?.user?.photo)} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem >
                    <Stack direction={"column"} alignItems={"flex-start"}>
                      <Stack direction={"row"} spacing={1} alignItems={"center"} onClick={handleProfile}>
                        <Avatar sx={{ bgcolor: deepPurple[500] }} alt={getUser("user")?.user?.nom + " " + getUser("user")?.user?.prenom} src={getDefaultImage(getUser("user")?.user?.photo)} />
                        <Typography>Profile</Typography>
                      </Stack>

                      <Divider />
                      <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <img src={logout_icon} alt="logout icon" onClick={deconnect} style={{ cursor: "pointer" }} />
                        <Typography onClick={deconnect}>Deconnexion</Typography>
                      </Stack>
                    </Stack>
                    {/* <img src={setting_icon} alt="setting icon" /> */}
                  </MenuItem>

                  {/* <MenuItem onClick={deconnect}>
          <ListItemIcon>
            <Logout fontSize="small"  sx={{color: main_blue}} />
          </ListItemIcon>
          Deconnexion
        </MenuItem> */}
                </Menu>
              </React.Fragment>
            </Stack>
            </Stack>
          </Stack>

        </Stack>
      </Box>
    </>
  );
}
