import KjosBackLog from "../component/KjosBackdrop";
import { useSelector } from "react-redux";
import useIsAuthenticate from "../hooks/useIsAuthenticate";
import { useEffect } from "react";

function  IsAuthUser(props) {
  const {isAuthenticated, isAuthUser} = useIsAuthenticate(); //will check if user is authenticated
  // const { isauth } = useSelector((state) => state.authentication);
  useEffect(() => {
    

  },[isAuthUser, isAuthenticated])
  return (
    <>
      {/* Backlog to load spinner when page is loading  */}
      {isAuthenticated ? props.component : <KjosBackLog open={!isAuthenticated} />}
    </>
  );
}

export default IsAuthUser