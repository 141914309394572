export const BASE_URL_IMAGE = "/images";
export const logo = "/images/image/logo.png";
export const logo_guinee = "/images/image/guinee.png";
export const logo_guinee_white = "/images/image/guinee-white.png";
export const login_image = "/images/image/login-image.jpg";
export const logo_login = "/images/image/login.png";
export const logout_icon = "/images/icon/logout_icon.png";
export const null_image = "/images/profil/null.png";
export const image_agence = "/images/image/study_abroad_pana_1.png";
export const image_agence2 = "/images/image/study_abroad_pana_2.png";
export const image_agence3 = "/images/image/study_abroad_pana_3.png";
export const signalement_assistance_icon = "/images/icon/signalement_assistance_icon.png";
export const facebook_icon = "/images/icon/facebook.png";
export const instagram_icon = "/images/icon/instagram.png";
export const twitter_icon = "/images/icon/twitter.png";
export const linkedin_icon = "/images/icon/linkedin.png";
export const signalement_icon = "/images/icon/signalement_icon.png";
export const success_icon = "/images/icon/success.svg";
export const upload_file_icon = "/images/icon/upload_file_icon.png";
export const delete_icon = "/images/icon/delete_icon.png";
export const signalement_anonyme_icon = "/images/icon/signalement_anonyme_icon.png";
export const avatar_anonyme = "/images/profil/avatar_anonyme.jpg";
export const avatar_anonyme_boy = "/images/profil/avatar_anonyme_boy.jpg";
export const avatar_anonyme_girl = "/images/profil/avatar_anonyme_girl.jpg";
export const police_image = "/images/image/police.jpg";
export const armorie1_image = "/images/image/armoirie 1.jpg";
export const armoiri2_image = "/images/image/armoirie 2.jpg";
export const police_logo = "/images/image/police_logo.png";
