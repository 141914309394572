import FinanceIcon from "./icons/FinanceIcon";
import "./button.css";

export default function Button(props) {
  const { onClick, icon, title, color, bgColor, active, disabled, height, width, blinking, className } = props;
  return (
    <>
      <button disabled={disabled} className={`frame-1000001958 ${blinking ? "blinking-button" : ""} ${disabled ? "disabled" : ""} ${active ? "active" : ""}`} onClick={onClick} style={{ height: height, backgroundColor: bgColor, width: width }}>
        {icon}
        <div className={`nouveau-signalement ${className}`} style={{ color: color, fontWeight: "bold" }}> {title} </div>
      </button>
    </>
  );
}
