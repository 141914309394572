import { Box, Stack, Typography } from "@mui/material";
import KjosMenuDrawer from "../component/KjosMenuDrawer";

export default function Assistance(){
   return (
      <KjosMenuDrawer> 
      <Box sx={{ width: "100%", p:10 }}>
         <h1 className="text-center">Vous avez besoin d'assistance</h1>
         <Stack direction={"column"} alignContent={"center"} alignItems={"center"}>
            <Stack direction={"row"} spacing={2}>
               <Typography variant="body">Contact </Typography>
               <Typography variant="body"><a href="tel:+224625486844">+224625486844</a>  </Typography>
            </Stack>

            <Stack direction={"row"} spacing={2}>
               <Typography variant="body">Email </Typography>
               <Typography variant="body"> <a href="mailto:anssi.gov.gn">support@anssi.gov.gn</a>  </Typography>
            </Stack>
         </Stack>
      </Box>
      </KjosMenuDrawer>
   )
}