import { useCallback } from "react";
import useLocalSorage from "./useLocalSorage";
import KjosHttp from "../controller/KjosHttp";
import { BASE_URL_API, LOGOUT_URL } from "../config/RoutesUrl";
import { useNavigate } from "react-router-dom";
import { SIGNIN_PATH } from "../config/PathUrl";
import useHttp from "./useHttp";
import useUser from "./useUser";

function useDeconnect() {
  const [getLocalStorage, removeLocalStorage, setLocalStorage] = useLocalSorage();
  const {postData} = useHttp();
  const {getUser} = useUser();
  const navigate = useNavigate();
  const deconnect = useCallback(async (e, url = BASE_URL_API + LOGOUT_URL, item = "user", redirect = "/") => {
    const token = getLocalStorage(item)?.token; // get token
    const config = { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } }; // get config
    try {
      const req = await postData(null, url, config); // ping the route
      if (!req?.data?.error) {
        removeLocalStorage(item); 
        if(!getUser("user")){
          navigate(redirect);
        }
      }
    } catch (error) {
      
    }
  },[getLocalStorage, removeLocalStorage, navigate, setLocalStorage, postData]);

  return {deconnect};
}
export default useDeconnect;
