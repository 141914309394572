import { useCallback } from "react";

function useLocalSorage() {
  const getLocalStorage = useCallback((item) => {
    const storage = localStorage.getItem(item);
    if (storage === undefined || storage === null || storage === "undefined" || typeof storage === "undefined") return null;
    const items = JSON.parse(storage);
    return items;
  }, []);

  const removeLocalStorage = useCallback((item) => {
    localStorage.removeItem(item);
  },[]);

  const setLocalStorage = useCallback((item, value) => {
    localStorage.setItem(item, JSON.stringify(value));
  },[]);

  return [getLocalStorage, removeLocalStorage, setLocalStorage];
}

export default useLocalSorage;
