import "./sendicon.css";
export default function SendIcon(props) {
  const { style } = props;

  return (
    <svg className="huge-icon-communication-outline-sent-fast" width="96" height="97" viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_460_71)">
        <path d="M40.9209 55.4203L48.0006 48.5M23.8185 64.2267L27.864 60.2723M31.7274 72.3178L35.7729 68.3633M27.5465 37.8794L61.3595 27.0254C66.6418 25.3298 71.5891 30.3911 69.7737 35.6333L58.1528 69.1905C56.0859 75.1589 47.6801 75.2566 45.4822 69.3379L41.5168 58.6594C40.8539 56.8744 39.4656 55.4541 37.6961 54.7508L27.1108 50.5433C21.2435 48.2112 21.5326 39.8099 27.5465 37.8794Z" stroke="url(#paint0_linear_460_71)" stroke-width="4.24291" stroke-linecap="round" />
      </g>
      <defs>
        <linearGradient id="paint0_linear_460_71" x1="11.9551" y1="52.0902" x2="60.1896" y2="14.3885" gradientUnits="userSpaceOnUse">
          <stop stop-color="#212759" />
          <stop offset="1" stop-color="#4A90E2" />
        </linearGradient>
        <clipPath id="clip0_460_71">
          <rect width="67.8866" height="67.8866" fill="white" transform="translate(0 47.9536) rotate(-44.3478)" />
        </clipPath>
      </defs>
    </svg>
  );
}
