import { Avatar, Box, Divider, Button as ButtonMui, IconButton, Menu, MenuItem, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import Logo from "../logo/Logo";
import Button from "../buttons/Button";
import { main_blue, main_gray } from "../../config/Color";
import FinanceIcon from "../buttons/icons/FinanceIcon";
import LogoGuinee from "../logo/LogoGuinee";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD_PATH, PRE_PLAINTE_FAITS_PATH, PRE_PLAINTE_FIN_PATH, PRE_PLAINTE_IDENTITE_PATH, PRE_PLAINTE_SERVICE_PATH, PROFIL_PATH, SIGNALEMENT, SIGNALEMENT_CREATE_FIN_PATH, SIGNALEMENT_CREATE_PATH, SIGNALEMENT_CREATE_SUIVANT_PATH, SIGNALEMENT_PRE_PLAINTE_PATH, SIGNALEMENT_SUIVIE_PATH, SIGNIN_PATH } from "../../config/PathUrl";
import useUser from "../../hooks/useUser";
import useDeconnect from "../../hooks/useDeconnect";
import getDefaultImage from "../../controller/getDefaultImage";
import { logout_icon, null_image, setting_icon } from "../../config/Image";
import { deepPurple } from "@mui/material/colors";
import React, { useEffect, useState } from "react";
import useActiveLink from "hooks/useActiveLink";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import useHttp from "hooks/useHttp";
import { CMS_GET } from "config/RoutesUrl";
import { Home } from "@mui/icons-material";

const linkGroup = [SIGNALEMENT_SUIVIE_PATH, SIGNALEMENT_PRE_PLAINTE_PATH, SIGNALEMENT_CREATE_PATH, SIGNALEMENT_CREATE_SUIVANT_PATH, SIGNALEMENT_CREATE_FIN_PATH, PRE_PLAINTE_FAITS_PATH, PRE_PLAINTE_FIN_PATH, PRE_PLAINTE_IDENTITE_PATH, PRE_PLAINTE_SERVICE_PATH];

const createSignelementLinkGroup = [SIGNALEMENT_CREATE_PATH, SIGNALEMENT_CREATE_SUIVANT_PATH, SIGNALEMENT_CREATE_FIN_PATH];

const createPrePlainteLinkGroup = [PRE_PLAINTE_FAITS_PATH, PRE_PLAINTE_FIN_PATH, PRE_PLAINTE_IDENTITE_PATH, PRE_PLAINTE_SERVICE_PATH, SIGNALEMENT_PRE_PLAINTE_PATH];

const suivieSignalementLinkGroup = [SIGNALEMENT_SUIVIE_PATH];

export default function HeaderConnecte() {
  const { getUser } = useUser();
  const navigate = useNavigate();
  const { deconnect } = useDeconnect(); // deconnect user
  const location = useLocation();
  const pathname = location.pathname;
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleProfile = () => {
    navigate(PROFIL_PATH);
  };

  const { mustBeDisabled } = useActiveLink();
  const [show, setShow] = useState(false);

  const defaultSiteSlogan = (
    <>
      {/*  Portail officiel de signalement <br />
      des contenus illicites de l’internet */}
    </>
  );

  const { getData } = useHttp(); // get data from server
  const [cms, setcms] = useState({});
  useEffect(() => {
    getData(CMS_GET).then((data) => {
      if (data?.error) {
        return;
      }
      setcms(data?.data);
    });
  }, []);

  return (
    <>
      <Box
        component="header"
        sx={{
          p: 0,
          m: 0,
          bgcolor: "white",
        }}
      >
        <Stack direction="column" spacing={2}>
          {/* with Logo */}
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={{ xs: 1, lg: 6 }} sx={{ bgcolor: main_blue, color: "white" }}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={{ xs: "flex-start", lg: "center" }}>
              <Link to={"/"}>
                <Logo width="5rem" logo_url={getDefaultImage(cms?.header?.logo_url, null_image)} />
              </Link>
              <Typography sx={{ fontSize: { xs: "0.7rem", md: "1rem", lg: "1.2rem" }, ml: { lg: 2 }}}>{cms?.header?.site_slogan ? <div dangerouslySetInnerHTML={{ __html: cms?.header?.site_slogan.replace(/<\/?p>/g, "") }}></div> : defaultSiteSlogan}</Typography>
            </Stack>

            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              <React.Fragment>
                <Box sx={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                  <Tooltip title="Account settings">
                    <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }} aria-controls={open ? "account-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined}>
                      <Avatar sx={{ bgcolor: deepPurple[500] }} alt={getUser("user")?.user?.nom + " " + getUser("user")?.user?.prenom} src={getDefaultImage(getUser("user")?.user?.photo)} />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 20000,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem>
                    <Stack direction={"column"} alignItems={"flex-start"}>
                      <Stack direction={"row"} spacing={1} alignItems={"center"} onClick={handleProfile}>
                        <Avatar sx={{ bgcolor: deepPurple[500] }} alt={getUser("user")?.user?.nom + " " + getUser("user")?.user?.prenom} src={getDefaultImage(getUser("user")?.user?.photo)} />
                        <Typography>Profile</Typography>
                      </Stack>

                      <Divider />
                      <Stack direction={"row"} spacing={1} alignItems={"center"}>
                        <img src={logout_icon} alt="logout icon" onClick={deconnect} style={{ cursor: "pointer" }} />
                        <Typography onClick={deconnect}>Deconnexion</Typography>
                      </Stack>
                    </Stack>
                    {/* <img src={setting_icon} alt="setting icon" /> */}
                  </MenuItem>

                  {/* <MenuItem onClick={deconnect}>
          <ListItemIcon>
            <Logout fontSize="small"  sx={{color: main_blue}} />
          </ListItemIcon>
          Deconnexion
        </MenuItem> */}
                </Menu>
              </React.Fragment>
            </Stack>
          </Stack>

          {/* Divider */}
          {/* <Divider sx={{ bgcolor: "#e1e1e1", borderWidth: 1, borderStyle: "solid" }} /> */}

          {/* With button 3 */}
          <Box sx={{ width: "100%", position: { xs: "relative", lg: "initial" }, overflow: { xs: show ? "initial" : "hidden", lg: "initial" }, height: { xs: "auto", lg: "initial" }, bgcolor: "white" }}>
            <Stack direction={{ xs: "column", lg: "row" }} py={{ xs: 2, lg: 0 }} justifyContent={{ xs: "center", lg: "space-between" }} alignItems={{ xs: "flex-start", lg: "center" }} px={6} sx={{ bgcolor: "white", position: { xs: "absolute", lg: "initial" }, zIndex: { xs: 10000, lg: 0 }, top: { xs: 0, lg: "initial" }, left: { xs: 0, lg: "initial" }, transform: { xs: show ? "translateX(0)" : "translateX(200%)", lg: "initial" }, transition: { xs: "transform 0.3s ease-in-out", lg: "none" }, width: { xs: "100%", lg: "initial" } }} spacing={{ xs: 2, lg: 0 }}>
              <Stack direction={{ xs: "column", lg: "row" }} alignItems={"center"} spacing={1}>
                {/* Button retour */}

                {/* <ButtonMui variant="outlined" startIcon={<ArrowBackSharpIcon />} onClick={() => navigate(-1)}>
                  Retour
                </ButtonMui> */}

                <Button
                  active={pathname === DASHBOARD_PATH}
                  onClick={() => {
                    navigate(DASHBOARD_PATH);
                  }}
                  title={
                    <Stack direction={"row"} spacing={1}>
                      <Home /> <Typography variant="body1">Accueil</Typography>
                    </Stack>
                  }
                />

                <Button active={pathname === SIGNALEMENT_CREATE_PATH || pathname === SIGNALEMENT_CREATE_SUIVANT_PATH || pathname === SIGNALEMENT_CREATE_FIN_PATH} icon={<FinanceIcon />} title="Nouveau Signalement" onClick={() => navigate(SIGNALEMENT_CREATE_PATH)} /* disabled={mustBeDisabled(createSignelementLinkGroup, linkGroup)} */ /* disabled={pathname === SIGNALEMENT_SUIVIE_PATH || pathname === PRE_PLAINTE_FAITS_PATH || pathname === PRE_PLAINTE_FIN_PATH || pathname === PRE_PLAINTE_IDENTITE_PATH || pathname === PRE_PLAINTE_SERVICE_PATH || pathname === SIGNALEMENT_PRE_PLAINTE_PATH} */ />

                <Button active={pathname === SIGNALEMENT_SUIVIE_PATH || pathname === SIGNALEMENT} title="Suivi de signalement" onClick={() => navigate(SIGNALEMENT_SUIVIE_PATH)} /* disabled={mustBeDisabled(suivieSignalementLinkGroup, linkGroup)} */ /* disabled={pathname === PRE_PLAINTE_FAITS_PATH || pathname === PRE_PLAINTE_FIN_PATH || pathname === PRE_PLAINTE_IDENTITE_PATH || pathname === PRE_PLAINTE_SERVICE_PATH || pathname === SIGNALEMENT_PRE_PLAINTE_PATH || pathname === SIGNALEMENT_CREATE_PATH || pathname === SIGNALEMENT_CREATE_SUIVANT_PATH || pathname === SIGNALEMENT_CREATE_FIN_PATH} */ />

                {/* <Button active={pathname === PRE_PLAINTE_FAITS_PATH || pathname === PRE_PLAINTE_FIN_PATH || pathname === PRE_PLAINTE_IDENTITE_PATH || pathname === PRE_PLAINTE_SERVICE_PATH || pathname === SIGNALEMENT_PRE_PLAINTE_PATH} title="Pré-plainte" onClick={() => navigate(SIGNALEMENT_PRE_PLAINTE_PATH)} /> */}
              </Stack>
            </Stack>
          </Box>

          {/* Menu button on right top */}
          <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} px={1} sx={{ width: "100%" }}>
            <IconButton sx={{ ml: "auto", color: main_blue, display: { xs: "flex", lg: "none" } }} onClick={() => setShow(!show)}>
              <MenuIcon />
            </IconButton>
          </Stack>

          {/* Divider */}

          <Divider sx={{ bgcolor: "#e1e1e1", borderBottomWidth: 2, borderStyle: "solid", boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)" }} />
        </Stack>
      </Box>
    </>
  );
}
