import axios from "axios";
import { BASE_URL, BASE_URL_API, CSRF_COOKIES, REGISTER_URL } from "../config/RoutesUrl";
import { useDispatch } from "react-redux";
import { setAuth, seterror, setloading } from "../store/AuthenticationReducer";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useLocalSorage from "./useLocalSorage";
import { DASHBOARD_PATH, SIGNALEMENT_CREATE_PATH, SIGNIN_PATH } from "../config/PathUrl";
import { setAlert } from "store/SignalementReducer";
import { FANCY_GREEN } from "config/Color";

function useRegister(tokenUrl = BASE_URL + CSRF_COOKIES, registerURL = BASE_URL_API + REGISTER_URL) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getLocalStorage, removeLocalStorage, setLocalStorage] = useLocalSorage();

  const handleFormSubmit = useCallback(
    async (param, action = SIGNIN_PATH, event = true) => {
      
      var data = {};
      if(event){
      param.preventDefault(); // prevent default
      dispatch(setloading(true)); // start loader
      const formData = new FormData(param.currentTarget); // get form data
      let contact = formData.get("contact");
      contact = contact.replace(/[^\d]/g, '');
      data = {
        email: formData.get("email"),
        password: formData.get("password"),
        prenom: formData.get("prenom") ?? "",
        nom: formData.get("nom"),
        contact: contact,
        password_confirmation: formData.get("password_confirmation"),
        habilitations: formData.get("habilitations"),
        date_naissance: formData.get("date_naissance"),
        adresse: formData.get("adresse"),
        ville: formData.get("ville"),
        pays: formData.get("pays"),
        photo: formData.get("photo"),
        genre: formData.get("genre"), 
        role: "user",
      }; // get form data
    }else{
      data = param
    }

      // axios.defaults.withCredentials = true;
      try {

       const makeSignalement = data?.makeSignalement;
       const redirect = makeSignalement? SIGNALEMENT_CREATE_PATH : DASHBOARD_PATH;
        // await axios.get(tokenUrl); // ping the route
     
        const request = await axios.post(registerURL, data); // get user
        const response = request;
        
        if (response?.data?.error) {
          dispatch(seterror(response?.data?.error)); // save error
          dispatch(setAuth(response?.data?.data ?? response?.data)); // save error data
          dispatch(setAlert({ open: true, message: response?.data?.data?.message ?? response?.data?.message, color: "red" }));
        } else {
          setLocalStorage("isRegister", true); //success resgister
          dispatch(setAlert({ open: true, message: "Inscription reussie", color: FANCY_GREEN }));
          setLocalStorage("user", response?.data?.data);
          navigate(redirect);
        }
        dispatch(setloading(false)); // stop loader
      } catch (error) {
        dispatch(seterror({ serverError: true, error: error }));
      }
    },
    [dispatch, registerURL, tokenUrl]
  );

  return [handleFormSubmit];
}

export default useRegister;