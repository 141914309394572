export default function detectEmailOrNumber(input) {
   const emailRegex = /^\S+@\S+\.\S+$/;
   const numberRegex = /^\d+$/;
 
   if (emailRegex.test(input)) {
     return "email";
   } else if (numberRegex.test(input)) {
     return "phone";
   } else {
     return false;
   }
 }