import { Box, Container, Grid, Paper, Stack, SvgIcon, Tab, Typography } from "@mui/material";
import Logo from "../../component/logo/Logo";
import Input from "../../component/input/Input2";
import Select from "../../component/input/Select";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { USER_SOURCE_URL, USER_TYPE_SIGNALEMENT_URL } from "../../config/RoutesUrl";
import useHttp from "../../hooks/useHttp";
import HeaderConnecte from "component/header/HeaderConnecte";
import KjosHeader from "component/KjosHeader";
import Footer from "component/footer/Footer";
import { Link } from "react-router-dom";
import { PlusOne } from "@mui/icons-material";
import SignalementTemplate from "pages/signalements/layouts/SignalementTemplate";
import PlainteTemplate from "pages/signalements/layouts/PlainteTemplate";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Search } from "@mui/icons-material";
export default function SuivieSignalement() {
  const { getData, postData, putData, deleteData } = useHttp(); // get data from server
  const [typeSignalement, setTypeSignalement] = useState([]);
  const navigate = useNavigate();
  const [required, setRequired] = useState(false);
  const [source, setSource] = useState([]);
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [input, setInput] = useState({});

  const handleForward = (e) => {
    e.preventDefault();
    if (!input?.typeSign1 || input?.typeSign1 === "" || input?.typeSign1 === "null" || input?.typeSign1 === "undefined" || input?.typeSign1 === null || input?.typeSign1 === undefined) {
      setRequired(true);
      return;
    }
    // navigate(DESCRIPTION_PATH, { state: { typeSign1: input?.typeSign1, nextPage: true } });
  };

  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    
  }

  useEffect(() => {
    return (() => {
      setSearchValue("");
    })
  },[])
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
      >
        <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>
          <KjosHeader>Créer un signalement | ANSSI</KjosHeader>

          <HeaderConnecte />

          <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ height: "100%", width: "100%", py: 1 }} spacing={1}>
              <Typography variant="h6">{value == 4? "Rechercher par le numéro de la plaintes" : "Rechercher par le numéro du signalement"}</Typography>
            <Box  sx={{ width: "60%", borderRadius: "10px", boxShadow: "0px 0px 5px grey", p: 2, position: "relative" }}>
              <input type="text" onChange={handleSearch} style={{ border: "none", outline: "none", backgroundColor:"white", width: "80%" }} />
              <SvgIcon sx={{ position: "absolute", right: "5%", top: "50%", cursor: "pointer", width: "40px", height: "40px", transform: "translate(50%, -50%)" }}>
                <Search />
              </SvgIcon>
              </Box>

            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider", width: "100%", textAlign: "left" }}>
                <TabList onChange={handleChange} aria-label="Traitement" scrollButtons variant="scrollable" allowScrollButtonsMobile>
                  <Tab label="Nouveaux" value="1" />
                  <Tab label="En cours de traitement" value="2" />
                  <Tab label="Traités" value="3" />
                  {/* <Tab label="Mes plaintes" value="4" /> */}
                </TabList>
              </Box>
              <TabPanel value="1" sx={{ width: "100%" }}>
                <SignalementTemplate length={6} labelle="Signalements" status={"new,open, without_continuation"} searchValue={searchValue} />
              </TabPanel>
              <TabPanel value="2" sx={{ width: "100%" }}>
                <SignalementTemplate length={4} labelle="En cours" status={"pendding"} searchValue={searchValue}/>
              </TabPanel>
              <TabPanel value="3" sx={{ width: "100%" }}>
                <SignalementTemplate length={6} labelle="Traités" status={"delivery"} searchValue={searchValue}/>
              </TabPanel>
              {/* <TabPanel value="4" sx={{ width: "100%" }}>
                <PlainteTemplate length={1} labelle="Plainte" searchValue={searchValue}/>
              </TabPanel> */}
            </TabContext>
          </Stack>
        </Stack>
      </Box>

      <Footer />
    </>
  );
}
