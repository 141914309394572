import { useLocation } from 'react-router-dom'
import Error404 from './Error404'
export default function KjosNextPage(props){
   const {nextPage, children} = props
   const location = useLocation()

   return(
      <>
      {location.state?.nextPage? children : <Error404 />}
      </>
   )
}