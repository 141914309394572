import React, { useEffect, useState } from "react";
import { YMaps, Map, Placemark, ZoomControl, TrafficControl, RouteButton, RouteEditor, FullscreenControl, GeolocationControl, TypeSelector } from "@pbe/react-yandex-maps";
import axios from "axios";
import { Divider, IconButton, InputBase, Paper, Stack } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import { Link } from "react-router-dom";

const KjosYMaps = () => {
  const defaultState = {
    controls: [],
    center: [5.3484342, -4.1197554],
    zoom: 9,
  };
  const [myPlacemark, setmyPlacemark] = useState([5.3484342, -4.1197554]);
  const [mapState, setmapState] = useState({
    controls: [],
    center: [5.3484342, -4.1197554],
    zoom: 9,
  });
  const [mapLoadingState, setmapLoadingState] = useState("Map is loading...");
  const [searchResults, setsearchResults] = useState([]);
  const [isSearch, setisSearch] = useState(false);
  const [searchValue, setsearchValue] = useState("");

  const handleSearch = async (e) => {
    const search = e.target.value;
    setsearchValue(e.target.value);

    if (search != "") {
      setisSearch(true);
      const data = await axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=47176e70-35ab-4c57-ad03-fb27f0289b39&format=json&geocode=${search}&lang=fr-FR`);

      const adresses = data.data.response.GeoObjectCollection.featureMember;

      const coord = adresses[0]?.GeoObject?.Point?.pos?.split(" ");
      if (coord !== undefined) {
        const coords = [parseFloat(coord[1]), parseFloat(coord[0])];
        setsearchResults(adresses);

        //state
        setmapState({
          controls: [],
          center: coords,
          zoom: 15,
        });

        setmyPlacemark(coords);
      }
    } else {
      setisSearch(false);
    }
  };

  const handleMapClick = async (e) => {
    const coords = e._sourceEvent.originalEvent.coords;
    // const inputRef = searchInputRef.current;
    //state
    const data = await axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=47176e70-35ab-4c57-ad03-fb27f0289b39&format=json&geocode=${coords[1] + "," + coords[0]}&lang=fr-FR`);

    const adresses = data.data.response.GeoObjectCollection.featureMember;
    // inputRef.value = adresses[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted;
    setsearchValue(adresses[0].GeoObject.metaDataProperty.GeocoderMetaData.Address.formatted);

    setmyPlacemark(coords);
    setisSearch(false);
    // 
  };

  const searchSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("nomRequete", "test");
    formData.append("idRdv", 1);
    formData.append("searchValue", searchValue);
    formData.append("lat", myPlacemark[0]);
    formData.append("lon", myPlacemark[1]);

    // Deserialize FormData to an object
    const formDataObject = {};
    for (const [key, value] of formData.entries()) {
      formDataObject[key] = value;
    }
    

    // Deserialize FormData to a URLSearchParams object
    const searchParams = new URLSearchParams(formData);
    
  };

  const handleSearchResultsClick = async (e) => {
    e.preventDefault();

    const search = e.target.value;
    const inputRef = e.target.innerHTML;

    const data = await axios.get(`https://geocode-maps.yandex.ru/1.x/?apikey=47176e70-35ab-4c57-ad03-fb27f0289b39&format=json&geocode=${inputRef}&lang=fr-FR`);

    const adresses = data.data.response.GeoObjectCollection.featureMember;

    const coord = adresses[0]?.GeoObject?.Point?.pos?.split(" ");
    if (coord !== undefined) {
      const coords = [parseFloat(coord[1]), parseFloat(coord[0])];
      setsearchResults(adresses);

      //state
      setmapState({
        controls: [],
        center: coords,
        zoom: 15,
      });

      setmyPlacemark(coords);
    }

    setisSearch(false);
  };

  const searchArrowRightClick = async () =>{
    
    const formData = new FormData();
    formData.append("nomRequete", "test");
    formData.append("idRdv", 1);
    formData.append("searchValue", searchValue);
    formData.append("lat", myPlacemark[0]);
    formData.append("lon", myPlacemark[1]);
    
    
    }

  useEffect(() => {
    setmapLoadingState("");
  }, [mapState]);

  return (
    <Stack sx={{ width: "100%", height: "100%", borderRadius: "5px", bgColor: "white", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px", padding: "5px" }}>
      <h6 className="text-center"> {mapLoadingState} </h6>
      
      <YMaps query={{ lang: "fr_FR", apikey: "47176e70-35ab-4c57-ad03-fb27f0289b39<" }} preload={true}>
        <Map state={mapState} style={{ width: "100%", height: "70%" }} options={{ copyrightLogoVisible: false, copyrightUaVisible: false }} onClick={(e) => handleMapClick(e)}>
          <Placemark geometry={myPlacemark} />
          <ZoomControl options={{ float: "right" }} />

          <TrafficControl options={{ float: "right" }} /> 
          <RouteButton options={{ float: "right" }} />
          <RouteEditor />
          <FullscreenControl />
          <GeolocationControl options={{ float: "left" }} />
          <TypeSelector options={{ float: "right" }} />
          <ZoomControl options={{ float: "right" }} />





        </Map>
      </YMaps>

      {/* <Stack className="mt-2 d-flex flex-row justify-content-center">
        <Paper component="form" sx={{ p: "2px 4px", display: "flex", alignItems: "center", width: "100%" }} method="post" onSubmit={(e) => searchSubmit(e)}>
          <IconButton sx={{ p: "10px" }} aria-label="menu">
            <DeliveryDiningIcon />
          </IconButton>
          <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Où pouvons nous vous livrer?" inputProps={{ "aria-label": "Où pouvons nous vous livrer?" }} onChange={(e) => handleSearch(e)} value={searchValue} name="search" />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search" onClick={searchArrowRightClick}>
            <ArrowRightAltIcon />
          </IconButton>
        </Paper>
      </Stack> */}

      {/* <Stack sx={{ position: "relative" }}>
        <Stack
          ssx={{
            position: "absolute",
            bgcolor: "light",
            width: "100%",
            color: "text.dark",
            zIndex: 10000,
          }}
        >
          <Stack
            sx={{
              listStyleType: "none",
              p: 0,
              overflow: "auto",
              height: "6rem",
            }}
          >
            {searchResults.map((res, index) => (
              <Link
                to="#"
                sx={{
                  listStyleType: "none",
                  padding: 0,
                  [`&.${index}`]: {
                    color: "primary.main",
                  },
                }}
                key={index}
                onClick={(e) => handleSearchResultsClick(e)}
              >
                {res.GeoObject.name + ", " + res.GeoObject.description}
              </Link>
            ))}
          </Stack>
        </Stack>
      </Stack> */}
    </Stack>
  );
};

export default KjosYMaps;
