import { useCallback } from "react";

export default function useStatut(){

   const color = useCallback((statut) => {
         console.log(statut)
         if(statut === "new"){
            return "error"
         }else if(statut === "pendding"){
            return "warning"
         }else if(statut === "open" || statut === "take_charge"){
            return "primary"
         }else if(statut === "without_continuation"){
            return "secondary"
         }else if(statut === "delivery"){
            return "success"
         }
         else{
            return "error"

         }
   },[])

   const label = useCallback((statut) => {

      if(statut === "new"){
         return "Nouveau"
      }else if(statut === "pendding"){
         return "En cour"
      }else if(statut === "open"){
         return "Ouvert"
      }else if(statut === "without_continuation"){
         return "Sans suite"
      }else if(statut === "delivery"){
         return "Traité"
      }
      else if(statut === "take_charge"){
         return "Pris en charge"
      }
      else{
         return "Nouveau"

      }
},[])

return {label, color}
}