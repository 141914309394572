import { Box, Button as ButtonMui, Container, Grid, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import { FANCY_BLUE, main_blue, main_gray } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import InputRectangle from "../../component/input/InputRectangle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FORGET_PASSWORD_URL } from "../../config/RoutesUrl";
import Button from "../../component/buttons/Button";
// import ButtonSmall from "../../component/buttons/ButtonSmal";
import useIsAuthenticate from "../../hooks/useIsAuthenticate";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticate from "../../hooks/useAuthenticate";
import { useEffect, useState } from "react";
import { setAuth } from "../../store/AuthenticationReducer";
import { setAlert } from "../../store/SignalementReducer";
import KjosAlert from "../../component/KjosAlert";
import KjosNextPage from "../../component/KjosNextPage";
import { SIGNIN_PATH, SIGNUP_INFORMATION_PATH } from "../../config/PathUrl";
import detectEmailOrNumber from "controller/detectEmailOrNumber";
import useResetPassword from "hooks/useResetPassword";

export default function SignUpCodeConfirm() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { auth, loading, error } = useSelector((state) => state.authentication);
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState("");
  const email = location?.state?.email.replace(/\s/g, "");
  const type = detectEmailOrNumber(email);
  const { signupVerifyCode, signupReSendCode } = useResetPassword();
  const [resend, setResend] = useState(false);
  const value = (input) => {
    
    setCode(input);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = location?.state?.email.replace(/\s/g, "");

    const type = detectEmailOrNumber(email);
    if (!type) {
      dispatch(
        setAlert({
          open: true,
          message: "Email ou numéro invalide",
          color: "rgba(254, 0, 0, 1)",
        })
      );
      return;
    }
    const data = {
      email: email,
      role: "user",
      type: type,
      code: code,
    };
    const result = await signupVerifyCode(data);
    if (result?.error) {
      const messages = result?.message;
      let message;
      Object.keys(messages).forEach((key) => (message = Array.isArray(messages[key]) && messages[key][0]));

      dispatch(
        setAlert({
          open: true,
          message: result?.validator ? message : result?.message,
          color: "rgba(254, 0, 0, 1)",
        })
      );
      return;
    } else {
      // navigate(SIGNIN_PATH)
      navigate(SIGNUP_INFORMATION_PATH, { state: { message: result?.message, email: email, nextPage: true } });
    }
  };

  const handleResendeCode = () => {
    setResend(true);
    const email = location?.state?.email?.replace(/\s/g, "");
    const type = detectEmailOrNumber(email);
    if (!type) {
      dispatch(
        setAlert({
          open: true,
          message: "Email ou numéro invalide",
          color: "rgba(254, 0, 0, 1)",
        })
      );
      return;
    }
    const data = {
      email: email,
      role: "user",
      type: type,
    };

    const result = signupReSendCode(data);
    if (result?.error) {
      dispatch(
        setAlert({
          open: true,
          message: "Une erreur s'est produite, veuillez réessayer",
          color: "rgba(254, 0, 0, 1)",
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          message: "Le code a été renvoyé",
          color: FANCY_BLUE,
        })
      );
      setResend(false);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setAuth({}));
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, [dispatch]);

  return (
    <KjosNextPage>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <form onSubmit={handleSubmit} style={{ height: "100%" }}>
          <KjosAlert label={alert.message} />

          {isSmallScreen && (
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={{ xs: 3, md: 6 }} sx={{ height: { md: "100%", width: "100%" } }}>
              <Logo />
              <Box sx={{ marginY: { md: "auto" } }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      color: main_blue,
                    },
                    textAlign: "center",
                  }}
                >
                  Bienvenue dans la version web de ANSSI
                </Typography>
                <Typography variant="h6" sx={{ fontSize: { xs: "0.8rem" } }}>
                  Vous souhaitez signaler un contenu illicite de l’internet ?
                </Typography>
              </Box>
            </Stack>
          )}
          <Grid container sx={{ height: "100%" }}>
            {isMediumScreen && (
              <Grid item xs={12} md={6} sx={{ bgcolor: { md: main_gray }, height: { md: "100%" }, p: 0 }}>
                <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"} p={{ xs: 3, md: 6 }} sx={{ height: { md: "100%" } }}>
                  <Logo />
                  <Box sx={{ marginY: { md: "auto" } }}>
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: {
                          xs: "1.5rem",
                          md: "5rem",
                          color: main_blue,
                        },
                      }}
                    >
                      Bienvenue dans la <br /> version web de <br /> ANSSI
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}>
                      Vous souhaitez signaler <br /> un contenu illicite de l’internet ?
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            )}

            <Grid item xs={12} md={6} sx={{ height: { md: "100%" } }}>
              <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={6} sx={{ height: { md: "100%" } }}>
                <Stack direction={"row"} justifyContent={"center"} spacing={2} alignItems={"center"} sx={{ width: "100%" }}>
                  <Typography variant="body1"> Avez vous déjà un compte? </Typography>
                  <ButtonMui
                    className="rectangle-3"
                    variant="contained"
                    sx={{ alignSelf: "flex-start" }}
                    onClick={() => {
                      navigate(SIGNIN_PATH);
                    }}
                  >
                    Se connecter
                  </ButtonMui>
                  {/* <button className="rectangle-3"> Inscription </button> */}
                </Stack>
                <Box sx={{ marginY: { md: "auto" } }}>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: {
                        xs: "2rem",
                        sm: "3rem",
                        md: "4rem",
                        color: main_blue,
                      },
                    }}
                  >
                    Confirmation
                  </Typography>
                  <Typography variant="h5" sx={{ color: main_blue }}>
                    du code réçu par {type === "phone" ? "SMS" : "mail"}
                  </Typography>

                  <br />
                  <Typography variant="body2">
                    veuillez saisir le code à 6 chiffres envoyé à <span style={{ color: main_blue }}> {location.state?.email.replace(/^(.{2}).*?(@.*)$/, "$1...$2")}</span>
                  </Typography>
                  <br />

                  <InputRectangle callBack={value} />

                  <br />
                </Box>

                {/* <ButtonSmall title="Suivant" sx={{alignSelf:"flex-start"}} type="submit" /> */}
                <Stack direction="row" spacing={2}>
                  <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)}>
                    Retour
                  </ButtonMui>

                  <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                    Suivant
                  </ButtonMui>
                </Stack>

                <br />

                <Typography variant="body1" sx={{ color: main_blue, marginTop: "auto", textAlign: "center" }}>
                  Vous n'avez reçu de code.
                </Typography>

                <Stack direction="row" spacing={2} alignItems={"center"}>
                  <ButtonMui variant="text" sx={{ color: "red" }} onClick={handleResendeCode}>
                    Renvoyer le code
                  </ButtonMui>
                  {resend && <Typography>Loading... </Typography>}
                </Stack>

                {/* Copyright */}
                <Typography variant="body1" sx={{ color: main_blue, marginTop: "auto", textAlign: "center" }}>
                  Copyright @ ANSSI 2023
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </KjosNextPage>
  );
}
