import { Box, Button as ButtonMui, Container, Grid, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import { FANCY_BLUE, main_blue, main_gray } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input2 from "../../component/input/Input2";
import InputPhone from "../../component/input/InputPhone";
import InputEmail from "../../component/input/InputEmail";
import InputText from "../../component/input/InputText";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FORGET_PASSWORD_URL } from "../../config/RoutesUrl";
import Button from "../../component/buttons/Button";
// import ButtonSmall from "../../component/buttons/ButtonSmal";
import useIsAuthenticate from "../../hooks/useIsAuthenticate";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticate from "../../hooks/useAuthenticate";
import { useEffect, useState } from "react";
import { setAuth, seterror } from "../../store/AuthenticationReducer";
import { setAlert } from "../../store/SignalementReducer";
import KjosAlert from "../../component/KjosAlert";
import KjosNextPage from "../../component/KjosNextPage";
import { CONDITION_UTILISATION_PATH, POLITIQUE_PATH, SIGNIN_PATH, SIGNUP_PASSWORD_PATH } from "../../config/PathUrl";
import detectEmailOrNumber from "controller/detectEmailOrNumber";
import useResetPassword from "hooks/useResetPassword";
import InputDate from "../../component/input/InputDate";
import Select from "../../component/input/Select";
import CheckBox from "../../component/input/CheckBox";
import useRegister from "hooks/useRegister";
import { login_image } from "config/Image";

export default function SignupInformation() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { auth, error } = useSelector((state) => state.authentication);
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState("");
  const { signupVerifyCode, signupReSendCode } = useResetPassword();
  const [resend, setResend] = useState(false);
  const [loading, setLoading] = useState(false);

  const value = (input) => {
    setCode(input);
  };
  const [handleFormSubmit] = useRegister();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget); // get form data

    const requestType = location?.state?.type
    let contact = null;
    let email = null;
    let countryCode = formData.get("countryCode");

    if(requestType === "phone"){
      contact =  location?.state?.email.replace(/\s/g, "");
      email =  formData.get("email");
    }else{
      if (countryCode) {
        contact = countryCode.replace(/\+/g, "") + formData.get("contact");
      }else{
        contact = formData.get("contact");

      }  
     email =  location?.state?.email.replace(/\s/g, "");
    }


    const nom = formData.get("nom");
    const prenom = formData.get("prenom");

    const data = {
      makeSignalement: location?.state?.makeSignalement,
      email: email,
      contact: contact,
      prenom: prenom,
      nom: nom,
      date_naissance: formData.get("date"),
      adresse: formData.get("adresse"),
      genre: formData.get("sexe"),
      role: "user",
    }; // get fo

 
    await handleFormSubmit(data, SIGNIN_PATH, false);

    // navigate(SIGNUP_PASSWORD_PATH, { state: { data: JSON.stringify(data), nextPage: true } });
  };

  const handleResendeCode = () => {
    setResend(true);
    const result = signupReSendCode({ email: location?.state?.email.replace(/\s/g, ""), role: "user" });
    if (result?.error) {
      dispatch(
        setAlert({
          open: true,
          message: "Une erreur s'est produite, veuillez réessayer",
          color: "rgba(254, 0, 0, 1)",
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          message: "Le code a été renvoyé",
          color: FANCY_BLUE,
        })
      );
      setResend(false);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setAuth({}));
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, [dispatch]);

  useEffect(() => {
    if(error) setLoading(!loading);
    dispatch(seterror(false))
  },[error]);

  return (
    <KjosNextPage>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <form onSubmit={handleSubmit} style={{ height: "100%" }}>
          <KjosAlert label={alert.message} />

          {isSmallScreen && (
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={{ xs: 3, md: 6 }} sx={{ height: { md: "100%", width: "100%" } }}>
              <Logo />
              <Box sx={{ marginY: { md: "auto" } }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      color: main_blue,
                    },
                    textAlign: "center",
                  }}
                >
                  Bienvenue dans la version web de ANSSI
                </Typography>
                <Typography variant="h6" sx={{ fontSize: { xs: "0.8rem" } }}>
                  Vous souhaitez signaler un contenu illicite de l’internet ?
                </Typography>
              </Box>
            </Stack>
          )}
          <Grid container sx={{ height: "100%" }}>
            {isMediumScreen && (
              <Grid item xs={12} md={6} sx={{ height: { md: "100%" }, p: 0 }}>
                <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"} sx={{ height: { md: "100%" }, overflow: "hidden", bgcolor: main_gray }}>
                  {/* <Logo /> */}
                  {/* <img src={login_image} style={{ width: "100%", height: "auto", objectFit: "contain" }} /> */}
                </Stack>
              </Grid>
            )}

            <Grid item xs={12} md={6} sx={{ height: { md: "100%" } }}>
              <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={6} sx={{ height: { md: "100%" } }}>
                <Box sx={{ marginY: { md: "auto" } }}>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: {
                        xs: "2rem",
                        sm: "3rem",
                        md: "4rem",
                        color: main_blue,
                      },
                    }}
                  >
                    Entrez vos <br /> informations
                  </Typography>
                  <br />
                  <InputText
                    label={
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                          },
                        }}
                      >
                        Nom*
                      </Typography>
                    }
                    type="text"
                    width="100%"
                    // error={error && auth?.email && error}
                    name="nom"
                    required
                  />{" "}
                  <br />
                  <InputText
                    label={
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                          },
                        }}
                      >
                        Prénom*
                      </Typography>
                    }
                    type="text"
                    width="100%"
                    // error={error && auth?.email && error}
                    name="prenom"
                    required
                  />
                  <br />
                  {location?.state?.type === "phone" ? (
                    <InputEmail
                      placeholder="exemple@example.com"
                      label={
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "16px",
                            },
                          }}
                        >
                          Email
                        </Typography>
                      }
                      type="text"
                      width="100%"
                      // error={error && auth?.email && error}
                      name="email"
                    />
                  ) : (
                    <InputPhone
                      placeholder="00 00 00 00 00"
                      label={
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "16px",
                            },
                          }}
                        >
                          Numéro de téléphone
                        </Typography>
                      }
                      type="text"
                      width="100%"
                      // error={error && auth?.email && error}
                      name="contact"
                      nameCode="countryCode"
                    />
                  )}
                  <br />
                  {/* <Input2 name="email" label="Email" type="email" required /> */}
                  <br />
                  {/* <Stack direction="row" spacing={2}>
                    <InputDate name="date" label="Date de naissance" required />
                    <Select
                      options={[
                        { id: "M", label: "Homme" },
                        { id: "F", label: "Femme" },
                      ]}
                      name="sexe"
                      label="Sexe"
                    />
                  </Stack> */}
                  <br />
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <CheckBox name="condition" required />
                    <Typography variant="body2">
                      J'accepte les{" "}
                      <Link sx={{ color: "#2b91ff", textDecoration: "none" }} to={CONDITION_UTILISATION_PATH}>
                        conditions générales
                      </Link>{" "}
                      d'utilisation et la{" "}
                      <Link sx={{ color: "#2b91ff", textDecoration: "none" }} to={POLITIQUE_PATH}>
                        politique de confidentialité
                      </Link>{" "}
                    </Typography>
                  </Stack>
                </Box>

                <Stack direction="row" spacing={2}>
                  <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)}>
                    Retour
                  </ButtonMui>

                  <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit" onClick={() => setLoading(!loading)}>
                    Valider
                  </ButtonMui>

                  {loading && (
                  <div className="spinner-border" role="status" style={{ color: main_blue }}>
                    <span className="sr-only"></span>
                  </div>
                )}
                </Stack>

                {/* Copyright */}
                <Typography variant="body1" sx={{ color: main_blue, marginTop: "auto", textAlign: "center" }}>
                  Copyright @ ANSSI 2023
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </KjosNextPage>
  );
}
