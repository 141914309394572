export const main_blue = "#212759";
export const main_black_082 = "rgba(0, 0, 0, 0.82)";
export const main_blue2 = "#2b91ff";
export const blue_sky = "#6495ED";
export const main_gray = "#E0E0E0";
export const blue_green = "#BEE7FE";
export const main_silver = "#C0C0C0";
export const main_red = "#D02424";
export const main_yellow = "#EFCB0F";
export const btn_gray = "#d9d9d9";
export const bg_black = "#2c2c2c";

export const FANCY_ORANGE = "#f8b64d";
export const FANCY_GREEN = "#59d8b6";
export const FANCY_BLUE = "#4099ff";
export const FANCY_PINK = "#f05170";
export const BG_COLOR = "#f2f7fb";

export const MAIN_BLUE = "#00308F";
export const MAIN_GREEN = "#15B79E";
export const MAIN_GRAY = "#F6F7FA";