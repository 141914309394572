import { Box, Button as ButtonMui, Container, Grid, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import { main_blue, main_gray } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import InputRectangle from "../../component/input/InputRectangle";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FORGET_PASSWORD_URL } from "../../config/RoutesUrl";
import Button from "../../component/buttons/Button";
// import ButtonSmall from "../../component/buttons/ButtonSmal";
import useIsAuthenticate from "../../hooks/useIsAuthenticate";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticate from "../../hooks/useAuthenticate";
import { useEffect, useState } from "react";
import { setAuth } from "../../store/AuthenticationReducer";
import { setAlert } from "../../store/SignalementReducer";
import KjosAlert from "../../component/KjosAlert";
import { SIGNUP_PATH } from "config/PathUrl";
import Header2 from "../../component/header/Header2";
import useResetPassword from "../../hooks/useResetPassword";
import { RESET_PASSWORD_CONFIRM_PATH } from "../../config/PathUrl";
import detectEmailOrNumber from "controller/detectEmailOrNumber";

export default function SigninPasswordForgetConfirm() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { auth, loading, error } = useSelector((state) => state.authentication);
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
  const [resend, setResend] = useState(false)
  const { verifyCode} = useResetPassword();
  const [code, setCode] = useState("");
  const location = useLocation();
  const value = (input) => {
    
    setCode(input);
  };


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const email = location?.state?.email
    const type = detectEmailOrNumber(email)
    if(!type){
      dispatch(setAlert({
        open: true,
        message: "Email ou numéro invalide",
        color: "rgba(254, 0, 0, 1)",
      }))
      return;
    }
    const data = {
      email: email,
      role: "user",
      type: type,
      code: code,
    };
    console.log(data) 
    const result = await verifyCode(data);

    if(result?.error){
      dispatch(setAlert({
        open: true,
        message: result?.message,
        color: "rgba(254, 0, 0, 1)",
      }))
    }else{
      // navigate(SIGNIN_PATH)
    navigate(RESET_PASSWORD_CONFIRM_PATH, { state: { data: JSON.stringify(data), nextPage: true } });

    }
  }

  const handleResendeCode = () => {
    setResend(true)
    const result = signupReSendCode({email: location?.state?.email.replace(/\s/g, ""), role: "user"})
    if(result?.error){
      dispatch(setAlert({
        open: true,
        message: "Une erreur s'est produite, veuillez réessayer",
        color: "rgba(254, 0, 0, 1)",
      }))
    }else{

      dispatch(setAlert({
        open: true,
        message: "Le code a été renvoyé",
        color: FANCY_BLUE,
      }))
      setResend(false)
    }

  }

  useEffect(() => {
    return () => {
      dispatch(setAuth({}));
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, [dispatch]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <KjosAlert label={alert.message} />
      <Header2 />

      <form onSubmit={handleFormSubmit} style={{ height: "100%" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={0} md={3}></Grid>
          <Grid item xs={"12"} md={6} sx={{ height: { md: "100%" } }}>
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={6} sx={{ height: { md: "100%" } }}>
              <Box sx={{ marginY: { md: "auto" } }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: {
                      xs: "2rem",
                      sm: "3rem",
                      md: "4rem",
                      color: main_blue,
                    },
                  }}
                >
                  Confirmation <br /> du code reçu par Email{" "}
                </Typography>
                <br />
                  <Typography variant="body2">veuillez saisir le code à 6 chiffres envoyé à  <span style={{ color: main_blue }}> {location.state?.email.replace(/^(.{2}).*?(@.*)$/, "$1...$2")}</span></Typography>
                  

                <br />
                <InputRectangle callBack={value} />
              </Box>

              {/* <ButtonSmall title="Suivant" sx={{alignSelf:"flex-start"}} type="submit" /> */}
              <Stack direction="row" spacing={2} >
              <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)} >
                Retour
              </ButtonMui>
              
              <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                Suivant
              </ButtonMui>
              </Stack>


              <br />
                
                <Typography variant="body1" sx={{ color: main_blue, marginTop: "auto", textAlign: "center" }}>
                  Vous n'avez reçu de code.
                  </Typography>
               
                <Stack direction="row" spacing={2} alignItems={"center"}>
                 <ButtonMui  variant="text" sx={{ color: "red" }} onClick={handleResendeCode}>
                    Renvoyer le code
                </ButtonMui>
                {resend &&<Typography>Loading... </Typography>}
                </Stack>


              {/* Copyright */}
              <Typography variant="body1" sx={{ color: main_blue, marginTop: "auto", textAlign: "center" }}>
                Copyright @ ANSSI 2023
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={0} md={3}></Grid>
        </Grid>
      </form>
    </Box>
  );
}
