import { useRef } from "react";
import { avatar_anonyme, delete_icon } from "../../config/Image";

export default function InputFile(props, { fileSizeConstraint = 10048576, fileSelectedConstraint = 6, errorLabelColor = "red", errorMsgFileSizeConstraint = "La taille de fichier ne doit pas dépasser 10Mo", errorMsgFileSelectedConstraint = "Le nombre d'image sélectionné ne doit pas dépasser 6" }) {
  var imgArray = [];
  var size = 0;

  const imageFactory = (e) => {
    // 
    const file = e.target.files;
    const files = Object.keys(file);
    var divErrorFileSize = document.createElement("div");
    var divErrorFileSelected = document.createElement("div");
    const errorFileListRef = props?.errorFileListRef?.current;

    // Lorsque cette option est utilisée les images s'afficheront dans l'lement spécifié avec reference ref
    const photoContainer = props?.refImage?.current;

    files.forEach((element, i) => {
      imgArray.push(file[element]);

      const photoUrl = URL.createObjectURL(file[element]);

      photoContainer.src = photoUrl;

      document.getElementById("removePhoto").addEventListener("click", () => {
        photoContainer.src = avatar_anonyme;
        delete imgArray[i];
      });
      size = size + file[element].size;

    });

    //   Contrainte sur la taille
    if (imgArray.length > fileSelectedConstraint) {
      imgArray = [];
      divErrorFileSelected.style.color = errorLabelColor;
      divErrorFileSelected.innerHTML = errorMsgFileSelectedConstraint;
      errorFileListRef.innerHTML = "";
      errorFileListRef.appendChild(divErrorFileSelected);
    } else {
      if (size > fileSizeConstraint) {
        // flush list of item previewly saved
        size = 0;
        imgArray = [];
        divErrorFileSelected.style.color = errorLabelColor;
        divErrorFileSelected.innerHTML = errorMsgFileSelectedConstraint;
        errorFileListRef.innerHTML = "";
        errorFileListRef.appendChild(divErrorFileSelected);
        divErrorFileSize.remove();
        divErrorFileSize.style.color = errorLabelColor;

        divErrorFileSize.innerHTML = errorMsgFileSizeConstraint;
        errorFileListRef.innerHTML = "";
        errorFileListRef.appendChild(divErrorFileSize);
      }
    }
  };

  const fileFactory = (e) => {
    
    const file = e.target.files;
    // 
    const files = Object.keys(file);
    var divListOfFile = document.createElement("div");
    var divErrorFileSize = document.createElement("div");
    var divErrorFileSelected = document.createElement("div");
    const errorFileListRef = props?.errorFileListRef?.current;
    const fileListRef = props?.fileListRef?.current;

    fileListRef.style.display = "flex";
    fileListRef.style.flexDirection = "column";
    fileListRef.style.width = "100%";

    files.forEach((element, i) => {
      imgArray.push(file[element]);

      var listOfFile = document.createElement("div");
      listOfFile.style.display = "flex";
      listOfFile.style.flexDirection = "row";
      listOfFile.style.justifyContent = "space-between";
      listOfFile.style.width = "100%";

      var divFileName = document.createElement("div");
      var divCloseIcon = props?.closeIconRef?.current ?? document.createElement("div");

      listOfFile.id = "listOfFile" + i;

      // close icon
      const deleteIcon = props?.deleteIconRef?.current ?? document.createElement("img");
      deleteIcon.src = delete_icon;
      deleteIcon.style.width = "1.5rem";
      deleteIcon.style.height = "1.5rem";
      deleteIcon.id = "deleteIcon" + i;

      deleteIcon.addEventListener("click", () => {
        listOfFile.remove();
        delete imgArray[i];
        // var fileBuffer = new DataTransfer();
        // for (var i = 0; i < imgArray.length; i++) {
        //   fileBuffer.items.add(imgArray[i]);
        // }
        // mainImageRef.current.files = fileBuffer?.files;
        // 
        props.mainImageRef.current.value = "";
      });

      divFileName.innerHTML = "-" + file[element].name;
      divCloseIcon.append(deleteIcon);

      listOfFile.appendChild(divFileName);
      props?.closeIconRef?.current ?? listOfFile.appendChild(divCloseIcon);

      divListOfFile.appendChild(listOfFile);
      size = size + file[element].size;
    });

    //   Contrainte sur la taille
    if (imgArray.length > fileSelectedConstraint) {
      imgArray = [];
      divErrorFileSelected.style.color = errorLabelColor;
      divErrorFileSelected.innerHTML = errorMsgFileSelectedConstraint;
      errorFileListRef.innerHTML = "";
      errorFileListRef.appendChild(divErrorFileSelected);
    } else {
      if (size > fileSizeConstraint) {
        // flush list of item previewly saved
        if (imgArray.length > 0) divListOfFile.innerHTML = "";
        size = 0;

        divErrorFileSize.remove();
        
        divErrorFileSize.style.color = errorLabelColor;

        divErrorFileSize.innerHTML = errorMsgFileSizeConstraint;
        errorFileListRef.innerHTML = "";
        errorFileListRef.appendChild(divErrorFileSize);
      } else {
        errorFileListRef.innerHTML = "";
        fileListRef.appendChild(divListOfFile);
      }
    }
  };

  const handleOnChange = (e) => {
    props?.imageMode ? imageFactory(e) : fileFactory(e);
  };
 
  return (
    <div style={{ width: props.width, height: props.height }} onClick={() => props?.mainImageRef?.current?.click()}>
      <div  style={{ width: props.width, height: props.height, border: props.border, borderRadius: props.borderRadius, backgroundColor: props.backgroundColor, position: "relative" }}>
        <input accept={props.accept} type="file" name={props.name} id={props.id} hidden onChange={handleOnChange} multiple={props.multiple} ref={props?.mainImageRef}  />
        {props?.imageMode ? 
          <div className="d-flex flew-row justify-content-center gap-5">
            <label  style={{height:"100%", width:"100%"}}>{props.editIcon}</label>
            <label id="removePhoto">{props.deleteIcon}</label>
          </div>
        : 
          <>
            <label >{props.component}</label>
          </>
        }

        <div  style={{backgroundColor: "red", width:"100%"}}>
          <div className="mb-3 position-absolute translate-middle bottom-0 start-50 w-100 text-center" style={{ color: props.colorLabel, fontSize: props.fontSizeLabel, position: "absolute", top: "50%" ,left: "50%", transform: "translate(50%, -50%)", bottom:0, width:"100%", textAlign: "center" }}>
            {props.label}
          </div>
        </div>

        <div  style={{position: "absolute", top: "0", left:"5%" , transform: "translateY(-50%)",  backgroundColor: "white" }}>
            {props.labelTop}
          </div>
      </div>
    </div>
  );
}
