import { Box, Button as ButtonMui, Container, Grid, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import { FANCY_BLUE, main_blue, main_gray } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input2 from "../../component/input/Input2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FORGET_PASSWORD_URL } from "../../config/RoutesUrl";
import Button from "../../component/buttons/Button";
// import ButtonSmall from "../../component/buttons/ButtonSmal";
import useIsAuthenticate from "../../hooks/useIsAuthenticate";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticate from "../../hooks/useAuthenticate";
import { useEffect, useState } from "react";
import { setAuth } from "../../store/AuthenticationReducer";
import { setAlert } from "../../store/SignalementReducer";
import KjosAlert from "../../component/KjosAlert";
import { SIGNUP_PATH } from "config/PathUrl";
import Header2 from "../../component/header/Header2";
import useResetPassword from "../../hooks/useResetPassword";
import { RESET_PASSWORD_CONFIRM_PATH, SIGNIN_PATH } from "../../config/PathUrl";
import detectEmailOrNumber from "controller/detectEmailOrNumber";

export default function SigninPasswordNew() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { auth, loading, error } = useSelector((state) => state.authentication);
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
  const [resend, setResend] = useState(false);
  const { initPassword } = useResetPassword();
  const [code, setCode] = useState("");
  const location = useLocation();
  const value = (input) => {
    
    setCode(input);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const stateData = JSON.parse(location?.state?.data);
    const formData = new FormData(e.currentTarget); // get form data
    const password = formData.get("password");
    const password_confirmation = formData.get("password_confirmation");
    if (password !== password_confirmation) {
      dispatch(
        setAlert({
          open: true,
          message: "Les mots de passe ne sont pas identiques",
          color: "rgba(254, 0, 0, 1)",
        })
      );
      return;
    }

    const data = {
      ...stateData,
      password: password,
      password_confirmation: password_confirmation,
      role: "user",
    }; // get fo
    const result = await initPassword(data);
    if (result?.error) {
      dispatch(
        setAlert({
          open: true,
          message: result?.message,
          color: "rgba(254, 0, 0, 1)",
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          message: "Mot de passe mis à jour",
          color: FANCY_BLUE,
        })
      );
      navigate(SIGNIN_PATH);
    }    
 };


  const handleResendeCode = () => {
    setResend(true);
    const result = signupReSendCode({ email: location?.state?.email.replace(/\s/g, ""), role: "user" });
    if (result?.error) {
      dispatch(
        setAlert({
          open: true,
          message: "Une erreur s'est produite, veuillez réessayer",
          color: "rgba(254, 0, 0, 1)",
        })
      );
    } else {
      dispatch(
        setAlert({
          open: true,
          message: "Le code a été renvoyé",
          color: FANCY_BLUE,
        })
      );
      setResend(false);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setAuth({}));
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, [dispatch]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <KjosAlert label={alert.message} />
      <Header2 />

      <form onSubmit={handleFormSubmit} style={{ height: "100%" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={0} md={3}></Grid>
          <Grid item xs={"12"} md={6} sx={{ height: { md: "100%" } }}>
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={6} sx={{ height: { md: "100%" } }}>
              <Box sx={{ marginY: { md: "auto" } }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: {
                      xs: "2rem",
                      sm: "3rem",
                      md: "4rem",
                      color: main_blue,
                    },
                  }}
                >
                  Nouveau mot de passe
                </Typography>
                <br />
                <Typography variant="body2">Veuillez saisir le nouveau mot de passe</Typography>

                <br />

                <Input2 name="password" label="Mot de passe" type="password" />
                <br />
                <Input2 name="password_confirmation" label="Confirmation" type="password" />
              </Box>

              {/* <ButtonSmall title="Suivant" sx={{alignSelf:"flex-start"}} type="submit" /> */}
              <Stack direction="row" spacing={2} >
              <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)} >
                Retour
              </ButtonMui>
              
              <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                Suivant
              </ButtonMui>
              </Stack>

              {/* Copyright */}
              <Typography variant="body1" sx={{ color: main_blue, marginTop: "auto", textAlign: "center" }}>
                Copyright @ ANSSI 2023
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={0} md={3}></Grid>
        </Grid>
      </form>
    </Box>
  );
}
