import { IconButton, Stack, Typography } from "@mui/material";
import "./input.css";
import { RemoveRedEye } from "@mui/icons-material";
import { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Email } from "@mui/icons-material";
import { Phone } from "@mui/icons-material";


export default function Input(prop) {
  const { placeholder, width = "100%", type, required, value, defaultValue, onChange, onKeyPress, disabled, style, label, emailPhone, error, name, id, inputRef, max } = prop;
  const [isPhone, setIsphone] = useState(true);
  const [passwordType, setPasswordType] = useState("password");

  const onChangeLocal = (e) => {
    const inputValue = e.target.value;
  
    // Check if the input starts with a number
    if (/^\d/.test(inputValue)) {
      // Format the input as "00 00 00 00 00"
      const formattedValue = inputValue.replace(/(\d{2})(?=\d)/g, "$1 ");
      e.target.value = formattedValue;
      setIsphone(true)
    } else {
      // Format the input as an email address
      const formattedValue = inputValue.toLowerCase();
      e.target.value = formattedValue;
      setIsphone(false)
    }

    inputValue==="" && setIsphone(true)
  };
  
  const onKeyDownLocal = (e) => {
    // Prevent entering non-numeric characters if the input starts with a number
    if (/^\d/.test(e.target.value)) {
      const keyCode = e.keyCode || e.which;
      const keyValue = String.fromCharCode(keyCode);
  
      if (!/^\d+$/.test(keyValue)) {
        e.preventDefault();
      }
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  return (
    <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"} sx={{width:width}}>
      <div tabIndex="-1" className="frame-1000002056-2" style={{ ...style, width: width }}>
        <input placeholder={placeholder} type={type === "password" ? passwordType : type} required={required} value={value} defaultValue={defaultValue} onChange={emailPhone ? onChangeLocal : onChange} disabled={disabled} className={"_00-00-00-00-00-2"} onKeyUp={emailPhone ? onKeyDownLocal : onKeyPress} name={name} id={id} autoComplete="off" max={max} />

        {type === "password" && (
          <IconButton tabIndex={-1} sx={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }} onClick={togglePasswordVisibility}>
            {passwordType === "password" ? <VisibilityOffIcon /> : <RemoveRedEye />}
          </IconButton>
        )}

        <IconButton tabIndex={-1} sx={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}>{emailPhone && (isPhone ? <Phone /> : <Email />)}</IconButton>

        <div className="frame-1000002030-2"  tabIndex="-1">
          <div className="email-ou-num-ro-de-t-l-phone-2"> {label} </div>
        </div>
      </div>

      <Typography variant="body1" sx={{ color: "red" }}>
        {" "}
        {error}{" "}
      </Typography>
    </Stack>
  );
}
