import { BASE_URL, BASE_URL_API } from "../config/RoutesUrl";
import authentication from "./Authentication";
import KjosHttp from "./KjosHttp";

class Controller {
  async signinUser(e) {
    // Get formata data
    const formData = new FormData(e.currentTarget);
    const data = {
      email: formData.get("email"),
      password: formData.get("password"),
    };
    const authRequest = await authentication(data);
    const requestData = authRequest?.data;
    if (!requestData?.error) localStorage.setItem("user", JSON.stringify(requestData));
    return requestData;
  }

  getLocalStorage(item) {
    const storage = localStorage.getItem(item);
    if (storage === undefined || storage === null || storage === "undefined" || typeof storage === "undefined") return null;
    const items = JSON.parse(storage);
    return items;
  }

  removeLocalStorage(item) {
    localStorage.removeItem(item);
  }

  setLocalStorage(item, value) {
    localStorage.setItem(item, JSON.stringify(value));
  }

  getConnexionState() {
    const user= this.getLocalStorage("user");
    if(!user?.error && user?.data) return true
    return false
  }
}

export default Controller;
