import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Fade from "@mui/material/Fade";
import Slide from "@mui/material/Slide";
import Grow from "@mui/material/Grow";
import { useEffect } from "react";
import MuiAlert from "@mui/material/Alert";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function KjosSnacBar(props) {
  const { onClose, open, message, autoHideDuration, severity } = props;

  return (
    <div>
      <Snackbar
        open={open}
        onClose={onClose}
        TransitionComponent={SlideTransition}
        key={SlideTransition.name}
        autoHideDuration={autoHideDuration}
      >
        <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
