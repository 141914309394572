import { Box, Button, Chip, Container, Grid, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "hooks/useHttp";
import { USER_HISTORIQUE_URL, USER_SIGNALLEMENT_URL } from "config/RoutesUrl";
import KjosSttepper from "component/KjosStepper";
import { useLocation, useNavigation } from "react-router-dom";
import KjosNextPage from "component/KjosNextPage";
import KjosMenuDrawer from "component/KjosMenuDrawer";
import useHistorique from "hooks/useHistorique";
import getDefaultImage from "controller/getDefaultImage";
import { Link } from "react-router-dom";
import KjosHeader from "component/KjosHeader";
import useStatut from "hooks/useStatut";
import Footer from "component/footer/Footer";
import HeaderConnecte from "component/header/HeaderConnecte";
const SignalementDetail = () => {
  const dispatch = useDispatch();
  const { getOneData, getData } = useHttp(); // get data from server
  const location = useLocation();
  const [oneDataResult, setOneDataResult] = useState({});
  const [history, setHistory] = useState([]);
  const { getHistorique } = useHistorique();
  const { color, label } = useStatut();

  useEffect(() => {
    
    async function fetchData() {
      const sign = await getOneData(location?.state?.id, USER_SIGNALLEMENT_URL);
      const hist = await getHistorique(location?.state?.id);
      setOneDataResult(sign);
      !hist?.error && setHistory(hist?.data);
      
    }
    fetchData(); // get data when component is mounted

    
  }, []);

  return (
    <KjosNextPage nextPage={location?.state?.nextPage}>
      <HeaderConnecte />
      <Box component={"main"} sx={{ flexGrow: 1, p: 5, backgroundColor: "#F6F7FA", borderRadius: "10px" }} className="content">
        <Container maxWidth={"xl"} sx={{ backgroundColor: "white", p: 5, borderRadius: 2, boxShadow: "0px 0px 5px grey" }}>
          <KjosHeader>Détail du signalement | ANSSI</KjosHeader>

          <Grid container spacing={2} sx={{ py: 4 }}>
            <Grid item md={12} lg={8} sx={{ p: 4 }}>
              <Stack
                spacing={2}
                sx={{
                  p: 4,
                  backgroundColor: "#F6F7FA",
                  borderRadius: "10px",
                  boxShadow: "0px 0px 5px grey",
                }}
                direction={"column"}
                justifyContent={"space-between"}
              >
                <Typography variant="h6">Détails</Typography>

                <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body">Numéro de signalement</Typography>
                  <Typography variant="body">{oneDataResult?.data?.code}</Typography>
                </Stack>

                <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body">Type</Typography>
                  <Typography variant="body">{oneDataResult?.data?.type_signalement?.libelle}</Typography>
                </Stack>

                 {/* <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body">Ville de l&apos;incident </Typography>
                  <Typography variant="body">{oneDataResult?.data?.localites}</Typography>
                </Stack> */}

                <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body">Source de données </Typography>
                  <Typography variant="body">{oneDataResult?.data?.source?.libelle ?? ""}</Typography>
                </Stack>

                {/* <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body">Utilisateur</Typography>
                  <Typography variant="body">{oneDataResult?.data?.user ? "" : "Utilisateur Anonyme"}</Typography>
                </Stack>  */}

                <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body">Date du signalement</Typography>
                  <Typography variant="body">{oneDataResult?.data?.date}</Typography>
                </Stack>

                <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                  <Typography variant="body">Status</Typography>
                  <Typography variant="body">
                    <Chip label={label(oneDataResult?.data?.statut)} color={color(oneDataResult?.data?.statut)} size="small" variant="contained" />
                  </Typography>
                </Stack>

                <Stack spacing={2} direction={"column"}>
                  <Typography variant="body">Description</Typography>
                  <Stack
                    sx={{
                      p: 4,
                      height: "10rem",
                      overflow: "auto",
                      backgroundColor: "white",
                      borderRadius: "10px",
                    }}
                  >
                    <Typography variant="body">{oneDataResult?.data?.description}</Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
            <Grid item md={12} lg={4} sx={{ p: 4 }}>
              <Grid item md={12} lg={12}>
                <Stack
                  direction="column"
                  justifyContent="space-between"
                  sx={{
                    backgroundColor: "white",
                    p: 4,
                    mt: 1,
                    borderRadius: 2,
                    boxShadow: "0px 0px 5px grey",
                    height: "15rem",
                    overflow: "auto",
                  }}
                >
                  <Typography variant="h6">Pièces jointes:</Typography>
                  <Stack direction={"column"} spacing={2}>
                    {oneDataResult?.data?.preuve?.split(",")?.map((preuv, index) => (
                      <Link to={getDefaultImage(preuv)} target="_blank" key={index}>
                        {" "}
                        <Typography variant="body" key={index}>
                          {index + 1} - Pièce jointe {index + 1}
                        </Typography>
                      </Link>
                    ))}
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer fixed={false} />
    </KjosNextPage>
  );
};

export default SignalementDetail;
