import { Box, Button as ButtonMui, Container, Grid, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import { main_blue, main_gray } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input from "../../component/input/Input";
import { Link, useNavigate } from "react-router-dom";
import { FORGET_PASSWORD_URL } from "../../config/RoutesUrl";
import Button from "../../component/buttons/Button";
// import ButtonSmall from "../../component/buttons/ButtonSmal";
import useIsAuthenticate from "../../hooks/useIsAuthenticate";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticate from "../../hooks/useAuthenticate";
import { useEffect } from "react";
import { setAuth } from "../../store/AuthenticationReducer";
import { setAlert } from "../../store/SignalementReducer";
import KjosAlert from "../../component/KjosAlert";
import { SIGNIN_PATH, SIGNUP_CONFIRM_PATH } from "../../config/PathUrl";
import useResetPassword from "hooks/useResetPassword";
import Select2 from "component/input/Select2";

export default function SignUp() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const {  alert } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
  const {signUpConfirm} = useResetPassword(); 
 

  useEffect(() => {
    return () => {
      dispatch(
        setAlert({
          open: false,
          message: "",
        }));
    };
  }, [dispatch]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <form onSubmit={signUpConfirm} style={{ height: "100%" }}>

      <KjosAlert label={alert.message} />

        {isSmallScreen && (
          <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={{ xs: 3, md: 6 }} sx={{ height: { md: "100%", width: "100%" } }}>
            <Logo />
            <Box sx={{ marginY: { md: "auto" } }}>
              <Typography
                variant="h1"
                sx={{
                  fontSize: {
                    xs: "1rem",
                    color: main_blue,
                  },
                  textAlign: "center",
                }}
              >
                Bienvenue dans la version web de ANSSI
              </Typography>
              <Typography variant="h6" sx={{ fontSize: { xs: "0.8rem" } }}>
                Vous souhaitez signaler un contenu illicite de l’internet ?
              </Typography>
            </Box>
          </Stack>
        )}
        <Grid container sx={{ height: "100%" }}>
          {isMediumScreen && (
            <Grid item xs={12} md={6} sx={{ bgcolor: { md: main_gray }, height: { md: "100%" }, p: 0 }}>
              <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"} p={{ xs: 3, md: 6 }} sx={{ height: { md: "100%" } }}>
                <Logo />
                <Box sx={{ marginY: { md: "auto" } }}>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: {
                        xs: "1.5rem",
                        md: "5rem",
                        color: main_blue,
                      },
                    }}
                  >
                    Bienvenue dans la <br /> version web de <br /> ANSSI
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}>
                    Vous souhaitez signaler <br /> un contenu illicite de l’internet ?
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          )}

          <Grid item xs={12} md={6} sx={{ height: { md: "100%" } }}>
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={6} sx={{ height: { md: "100%" } }}>
              <Stack direction={"row"} justifyContent={"center"} spacing={2} alignItems={"center"} sx={{ width: "100%" }}>
                <Typography variant="body1"> Avez vous déjà un compte? </Typography>
                <ButtonMui className="rectangle-3" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(SIGNIN_PATH)} >
                Se connecter
              </ButtonMui>
                {/* <button className="rectangle-3"> Inscription </button> */}
              </Stack>
              <Box sx={{ marginY: { md: "auto" } }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: {
                      xs: "2rem",
                      sm: "3rem",
                      md: "4rem",
                      color: main_blue,
                    },
                  }}
                >
                  Créer un compte
                </Typography>
                <Typography variant="h5" sx={{ color: main_blue }}>
                  ANSSI
                </Typography>

                <br />

                <Input
                  emailPhone={true}
                  placeholder="00 00 00 00 00"
                  label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                        },
                      }}
                    >
                      Email ou téléphone
                    </Typography>
                  }
                  type="text"
                  width="100%"
                  // error={error && auth?.email && error}
                  name="email"
                  nameCode="countryCode"
                />

                <br />

              </Box>

              {/* <ButtonSmall title="Suivant" sx={{alignSelf:"flex-start"}} type="submit" /> */}
              <Stack direction="row" spacing={2} >
              <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)} >
                Retour
              </ButtonMui>
              
              <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                Suivant
              </ButtonMui>
              </Stack>

              {/* Copyright */}
              <Typography variant="body1" sx={{ color: main_blue, marginTop: "auto", textAlign: "center" }}>
                Copyright @ ANSSI 2023
              </Typography>
            </Stack>
          </Grid>
        </Grid>
        </form>
    </Box>
  );
}
