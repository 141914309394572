import { useCallback } from "react";
import { FORGET_PASSWORD_URL, INIT_PASSWORD_URL, RESEND_CODE_URL, SIGNIN_CONFIRM_URL, SIGNUP_CONFIRM_URL, SIGNUP_RESEND_CODE_URL, SIGNUP_VERIFY_CODE_URL, VERIFY_CODE_URL } from "../config/RoutesUrl"
import useHttp from "./useHttp";
import { SIGNIN_CONFIRM_PATH, SIGNUP_CONFIRM_PATH, SIGNUP_INFORMATION_PATH } from "config/PathUrl";
import detectEmailOrNumber from "controller/detectEmailOrNumber";
import { setAlert, setLoading } from "store/SignalementReducer";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const useResetPassword = () => {
   const response = [];
   const {getData, postData} = useHttp();
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const resetPassword = useCallback(async (data, url = FORGET_PASSWORD_URL) =>{
     const response = await postData(data, url);
     return response
   }, [postData])

   const verifyCode = useCallback(async (data, url = VERIFY_CODE_URL) =>{
    const response = await postData(data, url);
    console.log(response)
    return response
  }, [postData])

  const initPassword = useCallback(async (data, url = INIT_PASSWORD_URL) =>{
    const response = await postData(data, url);
    console.log(response)
    return response
  }, [postData])

  const reSendCode = useCallback(async (data, url = RESEND_CODE_URL) =>{
    const response = await postData(data, url);
    console.log(response)
    return response
  }, [postData])

  const signupReSendCode = useCallback(async (data, url = SIGNUP_RESEND_CODE_URL) =>{
    const response = await postData(data, url);
    return response
  }, [postData])

  const signupVerifyCode = useCallback(async (data, url = SIGNUP_VERIFY_CODE_URL) =>{
    const response = await postData(data, url);
    console.log(response)
    return response
  }, [postData])

  const signUpConfirm = useCallback(async (e, url = SIGNUP_CONFIRM_URL) =>{
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    let email = formData.get("email").replace(/\s/g, "");
    let countryCode;
    const type = detectEmailOrNumber(email)
    if(type === "phone"){
      if(formData.get("countryCode")){
        countryCode = formData.get("countryCode").replace(/\+/g, "");
        email = countryCode + email
      }else{
        email = email
      }
    }
    if(!type){
      dispatch(setAlert({
        open: true,
        message: "Email ou numéro invalide",
        color: "rgba(254, 0, 0, 1)",
      }))
      
    }
    const data = {
      email: email,
      role: "user",
      type: type,
    };
    
    const result = await postData(data, url);

    if(result?.error){
      const messages  = result?.message
     let message 
     Object.keys(messages).forEach((key) => (
        message = Array.isArray(messages[key]) && messages[key][0]
     ))

      dispatch(setAlert({
        open: true,
        message: result?.validator ? message : result?.message,
        color: "rgba(254, 0, 0, 1)",
      }))
    }
    else {
    navigate(SIGNUP_CONFIRM_PATH, { state: { message: result?.message, email: data?.email, nextPage: true } });
    }
}, [postData])

const signInConfirm = useCallback(async (e, url = SIGNIN_CONFIRM_URL) =>{
  e.preventDefault();
  console.log("eeeeee", e)
  const formData = new FormData(e.target);
  let email = formData.get("email").replace(/\s/g, "");
  let countryCode
  const makeSignalement = formData.get("makeSignalement")
  
  const type = detectEmailOrNumber(email)
  if(type === "phone"){
    dispatch(setAlert({
      open: true,
      message: "Email invalide",
      color: "rgba(254, 0, 0, 1)",
    }))
    dispatch(setLoading(false))
    return
    if(formData.get("countryCode")){
      // countryCode = formData.get("countryCode").replace(/\+/g, "");
      // email = countryCode + email
    
    }else{
      email = email
    }
  }
  if(!type){
    dispatch(setAlert({
      open: true,
      message: "Email invalide",
      color: "rgba(254, 0, 0, 1)",
    }))
    dispatch(setLoading(false))
  }
  const data = {
    email: email,
    role: "user",
    type: type,
  };
  
  const result = await postData(data, url);
 console.log("result ========================", result)
  if(result?.error){

  //   //  If status 404 => user already exists
  // if(result?.status === 'HTTP_NOT_FOUND'){
  //   navigate(SIGNUP_INFORMATION_PATH, { state: { message: result?.message, email: email, type: type, nextPage: true } });
    
  // }
    
   const messages  = result?.message
   let message 
   Object.keys(messages).forEach((key) => (
      message = Array.isArray(messages[key]) && messages[key][0]
   ))
    dispatch(setAlert({
      open: true,
      message: result?.validator ? message : result?.message,
      color: "rgba(254, 0, 0, 1)",
    }))
  }
  else {
  navigate(SIGNIN_CONFIRM_PATH, { state: { message: result?.message, email: data?.email, nextPage: true, makeSignalement: makeSignalement, userExist: result?.userExist} });
  }
}, [postData])

   return {resetPassword, verifyCode, initPassword, reSendCode, signUpConfirm, signupVerifyCode, signupReSendCode, signInConfirm}
}

export default useResetPassword