import { useCallback } from "react";
import { ANONYME_HISTORIQUE_URL, USER_HISTORIQUE_URL } from "../config/RoutesUrl";
import useHttp from "./useHttp";
import getCurrentDate from "../controller/getCurrentDate";

export default function useHistorique(){
   const {postData, getData} = useHttp();


   const setHistorique = useCallback(
      async (status, signalement_id, admin_id, libelle, url = ANONYME_HISTORIQUE_URL) => {
        const data = {
          type: status,
          date: getCurrentDate(),
          signalement_id: signalement_id,
          admin_id: admin_id,
          libelle: libelle,
        };
        const historique = await postData(data, url);
        return historique;
      },
      [postData]
    );

   const getHistorique = useCallback(async (signalement_id, url =  USER_HISTORIQUE_URL) => {
      const historique = await getData( url + "?signalement_id=" + signalement_id);
      return historique
   },[])

   return {setHistorique, getHistorique}
}