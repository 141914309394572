import "./fileicon.css";
export default function FileIcon(props) {
  const {style} = props

   return (
      <div className="paperclip" style={style}>
      <svg
        className="paperclip2"
        width="26"
        height="25"
        viewBox="0 0 26 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.2494 7.81201L7.78845 16.0826C7.4837 16.3757 7.3125 16.7731 7.3125 17.1875C7.3125 17.6019 7.4837 17.9993 7.78845 18.2923C8.0932 18.5854 8.50652 18.75 8.9375 18.75C9.36848 18.75 9.7818 18.5854 10.0865 18.2923L20.1725 8.45922C20.4743 8.16904 20.7137 7.82454 20.877 7.4454C21.0403 7.06625 21.1244 6.65989 21.1244 6.24951C21.1244 5.83913 21.0403 5.43277 20.877 5.05363C20.7137 4.67448 20.4743 4.32999 20.1725 4.0398C19.8707 3.74962 19.5124 3.51943 19.1181 3.36239C18.7238 3.20534 18.3012 3.12451 17.8744 3.12451C17.4476 3.12451 17.025 3.20534 16.6307 3.36239C16.2364 3.51943 15.8781 3.74962 15.5763 4.0398L5.49035 13.8729C4.57611 14.752 4.0625 15.9443 4.0625 17.1875C4.0625 18.4307 4.57611 19.623 5.49035 20.5021C6.40459 21.3811 7.64457 21.875 8.9375 21.875C10.2304 21.875 11.4704 21.3811 12.3846 20.5021L20.7182 12.4995"
          stroke="#ADADAD"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    
   );
}