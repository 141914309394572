export const SIGNIN_PATH = "/auth/login";
export const SIGNUP_PATH = "/auth/register";
export const SIGNUP_CONFIRM_PATH = "/auth/signup-confirm";
export const SIGNIN_CONFIRM_PATH = "/auth/signin-confirm";
export const DASHBOARD_PATH = "/dashboard";
export const RESET_PASSWORD_PATH = "/reset-password";
export const SIGNUP_INFORMATION_PATH = "/auth/signup-information";
export const SIGNUP_PASSWORD_PATH = "/auth/password";
export const RESET_PASSWORD_CONFIRM_PATH = "/reset-password-confirm";
export const RESET_PASSWORD_CONFIRM_CODE_PATH = "/reset-password-confirm-code";
export const RECHERCHE_PATH = "/recherche";
export const RECHERCHE_ANONYME_PATH = "/recherche-anonyme";
export const CONTENU_ANONYME_PATH = "/contenu-anonyme";
export const CONTENU_PATH = "/contenu";
export const DESCRIPTION_PATH = "/description";
export const DESCRIPTION_PATH_ANONYME = "/description-anonyme";
export const SIGNALEMENT_LIST_PATH = "/signalement-list";
export const SIGNALEMENT_LIST_TRAITE_PATH = "/signalement-list-traite";
export const SIGNALEMENT_LIST_ENCOUR_PATH = "/signalement-list-encour";
export const SETTING_PATH = "/setting";
export const SIGNALEMENT_CREE = "/signalement/cree";
export const SIGNALEMENT_CREE_ANONYME = "/signalement/cree-anonyme";
export const SIGNALEMENT = "/signalement";
export const SIGNALEMENT_ANONYME = "/signalement-anonyme";
export const ASSISTANCE_PATH = "/assistance";
export const ASSISTANCE_ANONYME_PATH = "/assistance-anonyme";
export const PROFIL_PATH = "/profil"; 
export const PROFIL_EDIT_PATH = "/profil/edite";
export const SIGNALEMENT_CREATE_PATH = "/signalements/create";
export const SIGNALEMENT_SUIVIE_PATH = "/signalements/suivie";
export const SIGNALEMENT_CREATE_SUIVANT_PATH = "/signalements/create-suivant";
export const SIGNALEMENT_CREATE_FIN_PATH = "/signalements/create-fin";
export const SIGNALEMENT_PRE_PLAINTE_PATH = "/pre-plainte";
export const PRE_PLAINTE_SERVICE_PATH = "/pre-plainte/service";
export const PRE_PLAINTE_IDENTITE_PATH = "/pre-plainte/identite";
export const PRE_PLAINTE_FAITS_PATH = "/pre-plainte/faits";
export const PRE_PLAINTE_FIN_PATH = "/pre-plainte/fin";
export const PRE_PLAINTE_DETAIL_PATH = "/pre-plainte/detail";
export const POLITIQUE_PATH = "/politique-confidentialite";
export const CONDITION_UTILISATION_PATH = "/condition-utilisation";
export const ARTICLE_DETAIL_PATH = "/article/detail";
