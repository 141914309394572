import { Box, Button as ButtonMui, Container, Grid, Stack, Typography } from "@mui/material";
import { bg_black, main_blue2 } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input from "../../component/input/Input2";
import Select from "../../component/input/Select";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { USER_SOURCE_URL, USER_TYPE_SIGNALEMENT_URL } from "../../config/RoutesUrl";
import useHttp from "../../hooks/useHttp";
import HeaderConnecte from "component/header/HeaderConnecte";
import KjosHeader from "component/KjosHeader";
import Footer from "component/footer/Footer";
import { SIGNALEMENT_CREATE_SUIVANT_PATH } from "config/PathUrl";
export default function CreateSignalement() {
  const { getData, postData, putData, deleteData, getOneData } = useHttp(); // get data from server
  const [typeSignalement, setTypeSignalement] = useState([]);
  const navigate = useNavigate();
  const [required, setRequired] = useState(false);
  const [source, setSource] = useState([]);
  const [description, setDescription] = useState("");
  const [loadindDescription, setLoadingDescription] = useState(false);

  const [input, setInput] = useState({});
  const handleForward = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
  
    navigate(SIGNALEMENT_CREATE_SUIVANT_PATH, { state: { data: JSON.stringify(data), nextPage: true } });
  };

  const handleOnchange = async (e) => {
    setLoadingDescription(true);
    const {  value } = e.target;
    const desc = await getOneData(value, USER_TYPE_SIGNALEMENT_URL);
    setDescription(desc?.data?.description ?? "")
    setLoadingDescription(false);
  };

  useEffect(() => {
    async function fetchData() {
      const typeSign = (await getData(USER_TYPE_SIGNALEMENT_URL))?.data?.map((item) => ({ label: item.libelle, value: item.id, id: item.id}));
      setTypeSignalement(typeSign);
      // 
    }
    fetchData();

    getData(USER_SOURCE_URL).then((data) => {
      const src = data?.data?.map((item) => ({
        label: item.libelle,
        value: item.id,
        id: item.id,
      }));

      setSource(src);
    });
  }, [getData, setTypeSignalement, setSource]);
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
      >
        <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>
          <KjosHeader>Créer un signalement | ANSSI</KjosHeader>

          <HeaderConnecte />
          <form onSubmit={handleForward} style={{ width: "100%" }}>
            <Stack direction={"column"} sx={{ width: "100%", px: { xs: 1, md: 8 }, py: 2 }}>
              <Typography variant="h5">
              Parcours de signalement
              </Typography>
              <br />  <br /> <br />

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} gap={2}>
                    <Grid item xs={12}>
                      <Select label="Catégories" onChange={handleOnchange} options={typeSignalement} width="100%" name="type_signalement_id" />
                    </Grid>

                    <Grid item xs={12}>
                      <Select label="Sources" options={source} width="100%" name="source_id" />
                    </Grid>

                    {/* <Grid item xs={12}>
                      <Input label="Si autre, indiquer précisement" type="text" width="100%"  name="autre_sources" />
                    </Grid> */}
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                      <div className="w-100 p-2 h-100">
                        <div className="rounded rounded-2 border border-2 h-100 border-secondary">

                        <div className="w-100 h-100 d-flex justify-content-center align-items-center p-1">  {loadindDescription ? "Chargement..." : description}</div>
                        </div>
                      </div>
                </Grid>
              </Grid>
              <br /> <br /><br />

              <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"}  spacing={2}>

                <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                Suivant
              </ButtonMui>
                </Stack>

        
            </Stack>
          </form>
        </Stack>
      </Box>
      <Footer />
    </>
  );
}
