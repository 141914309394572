export default function FinanceIcon() {
   return <>
      <div className="remix-icons-line-finance-bank-card-line">
      <svg
        className="group"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.49935 3H17.4993C17.7204 3 17.9323 3.0878 18.0886 3.24408C18.2449 3.40036 18.3327 3.61232 18.3327 3.83333V16.3333C18.3327 16.5543 18.2449 16.7663 18.0886 16.9226C17.9323 17.0789 17.7204 17.1667 17.4993 17.1667H14.9993V15.5H16.666V9.66667H3.33268V15.5H4.99935V17.1667H2.49935C2.27834 17.1667 2.06637 17.0789 1.91009 16.9226C1.75381 16.7663 1.66602 16.5543 1.66602 16.3333V3.83333C1.66602 3.61232 1.75381 3.40036 1.91009 3.24408C2.06637 3.0878 2.27834 3 2.49935 3ZM16.666 4.66667V8H3.33268V4.66667H16.666Z"
          fill="#212759"
        />
        <path
          d="M13.3327 14.6667H10.8327V11.3333H9.16602V14.6667H6.66602L9.99935 18L13.3327 14.6667Z"
          fill="#212759"
        />
      </svg>
    </div>
   </>;
}