import "./tetxarea.css";
export default function TextArea(props) {
   const { defaultValue, type, name, onChange, placeholder, required, css , label, id, width, border} = props;

  return (
    <div className="frame-1000002065" style={{width:width, border: border}}>
      <div className="glisser-et-d-poser-un-fichier-ou-cliquer-pour-ajouter-une-pi-ce-jointe">Glisser et déposer un fichier ou cliquer pour ajouter une pièce jointe</div>
      <div className="frame-1000002030">
        <div className="description-du-fait"> {label}</div>
      </div>
      <textarea className="textarea" name={name} id={id} defaultValue={defaultValue} onChange={onChange} placeholder={placeholder} required={required} />
    </div>
  );
}
