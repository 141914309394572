export default function Error404() {
  return (
    <section className="page_404">
      <style>
         
      </style>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <div className="col-sm-10 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center ">404</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">Vous vous êtes égaré</h3>

                <p>La page que vous rechez n'est pas disponible!</p>

                <a href="/" className="link_404">
                  Accueil
                </a>
            </div>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
}
