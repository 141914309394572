import { Avatar, Box, Divider, IconButton, Stack, SvgIcon, Typography } from "@mui/material";
import Logo from "../logo/Logo";
import Button from "../buttons/Button";
import { main_blue } from "../../config/Color";
import FinanceIcon from "../buttons/icons/FinanceIcon";
import LogoGuinee from "../logo/LogoGuinee";
import { Link } from "react-router-dom";
import { facebook_icon, instagram_icon, linkedin_icon, twitter_icon } from "../../config/Image";
import { CONDITION_UTILISATION_PATH, POLITIQUE_PATH } from "config/PathUrl";
import { useEffect, useState } from "react";
import { CMS_GET } from "config/RoutesUrl";
import useHttp from "hooks/useHttp";
import getDefaultImage from "controller/getDefaultImage";
import { null_image } from "config/Image";

const SIDE_NAV_WIDTH = 280;
const TOP_NAV_HEIGHT = 64;
export default function Footer(props) {
  const { fixed = true } = props;

  const default_contact_url = "support@anssi.gov.gn";

  const { getData } = useHttp(); // get data from server
  const [cms, setcms] = useState({});
  useEffect(() => {
    getData(CMS_GET).then((data) => {
      if (data?.error) {
        return;
      }
      
      setcms(data?.data);
    });
  }, []);

  return (
    <>
      <Box
        component="footer"
        sx={{
          p: 0,
          m: 0,
          height: fixed ? { xs: "auto", lg: "50px" } : "auto",
          position: fixed ? { xs: "unset", lg: "fixed" } : "unset",
          width: "100%",
        }}
      >
        <Stack direction={{ xs: "column", lg: "row" }} spacing={2} sx={{ width: "100%", bgcolor: main_blue, coor: "white", height: "100%" }} justifyContent={"space-between"} alignItems={{ xs: "center", lg: "center" }} px={6} py={3}>
          <Stack direction={{ xs: "column", lg: "row" }} justifyContent={"center"} alignItems={"center"} spacing={2}>
            <Link style={{ color: "white", textDecoration: "none" }} to={"mailto:support@anssi.gov.gn"}>
              <Typography variant="body2" sx={{ color: "white" }}>
                {" "}
                <strong>Contactez-nous</strong> via <br /> {cms?.footer?.contact_url ?? default_contact_url}
              </Typography>
            </Link>
            <Stack direction={"column"}>
              <Typography variant="body2" sx={{ color: "white" }}>
                {" "}
                <Link style={{ color: "white" }} to={POLITIQUE_PATH}>
                  Politique de confidentialité
                </Link>{" "}
              </Typography>

              <Typography variant="body2" sx={{ color: "white" }}>
                <Link style={{ color: "white" }} to={CONDITION_UTILISATION_PATH}>
                  Condition d’utilisation
                </Link>{" "}
              </Typography>
            </Stack>
          </Stack>

          <div>
            <div className="container">
              <div className="row">
                <div className="col-6 col-md-3">
                  {/* Facebook */}
                  <Link style={{ color: "white", textDecoration: "none" }} to={cms?.footer?.facebook_url ?? "/"}>
                    {" "}
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      {" "}
                      <img src={getDefaultImage(cms?.footer?.facebook_logo, null_image)} style={{ width: "1.5rem", height: "1.5rem", objectFit: "cover" }} />
                      <Typography variant="body2" sx={{ color: "white" }}>
                        {" "}
                        {cms?.footer?.facebook_name ?? "Facebook"}
                      </Typography>
                    </Stack>{" "}
                  </Link>
                </div>

                <div className="col-6 col-md-3">
                  {/* Instagram */}
                  <Link style={{ color: "white", textDecoration: "none" }} to={cms?.footer?.instagram_url ?? "/"}>
                    {" "}
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      {" "}
                      <img src={getDefaultImage(cms?.footer?.instagram_logo, null_image)} style={{ width: "1.5rem", height: "1.5rem", objectFit: "cover" }} />
                      <Typography variant="body2" sx={{ color: "white" }}>
                        {" "}
                        {cms?.footer?.instagram_name ?? "Instagram"}
                      </Typography>
                    </Stack>{" "}
                  </Link>
                </div>

                <div className="col-6 col-md-3">
                  {/* Twitter */}
                  <Link style={{ color: "white", textDecoration: "none" }} to={cms?.footer?.twitter_url ?? "/"}>
                    {" "}
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      {" "}
                      <img src={getDefaultImage(cms?.footer?.twitter_logo, null_image)} style={{ width: "1.5rem", height: "1.5rem", objectFit: "cover" }} />
                      <Typography variant="body2" sx={{ color: "white" }}>
                        {" "}
                        {cms?.footer?.twitter_name ?? "Twitter"}
                      </Typography>
                    </Stack>{" "}
                  </Link>
                </div>

                <div className="col-6 col-md-3">
                  {/* Linkedin */}
                  <Link style={{ color: "white", textDecoration: "none" }} to={cms?.footer?.linkedin_url ?? "/"}>
                    {" "}
                    <Stack direction={"row"} alignItems={"center"} spacing={1}>
                      {" "}
                      <img src={getDefaultImage(cms?.footer?.linkedin_logo, null_image)} style={{ width: "1.5rem", height: "1.5rem", objectFit: "cover" }} />
                      <Typography variant="body2" sx={{ color: "white" }}>
                        {" "}
                        {cms?.footer?.linkedin_name ?? "Linkedin"}
                      </Typography>
                    </Stack>{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Stack>
      </Box>
    </>
  );
}
