import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Button, Grid, Stack, SvgIcon, Typography } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import formatDate2 from "controller/formatDate2";
import { FANCY_ORANGE, MAIN_GRAY, main_blue, main_black_082, main_gray } from "config/Color";
import { useLocation, useNavigate } from "react-router-dom";
import { ARTICLE_DETAIL_PATH } from "config/PathUrl";
import getDefaultImage from "controller/getDefaultImage";
import { TimerOutlined } from "@mui/icons-material";
import removeTags from "controller/removeTags";

export default function Article(props) {
  const { items, page, onChangePage, loading, count, detail, rowsPerPage } = props;
  const navigate = useNavigate();

  return (
    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} py={1} sx={{ width: "100%" }}>
      {/* Image Grid */}
      {detail ? (
        // Detail de l'article
        <>
          <Grid container spacing={8} p={0}>
            {loading ? (
              <Typography variant="h6" sx={{ fontWeight: "bold", position: "absolute", top: "50%", left: "50%", transform: "translate(50%, -50%)" }}>
                {" "}
                Chargement...
              </Typography>
            ) : (
              <>
                <Grid item xs={12} md={9} lg={8} order={{ xs: 2, md: 1 }}>
                  <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ width: "100%", bgcolor: "white", p: 4, borderRadius: 4 }}>
                    <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 0, md: 1 }} alignItems={"center"} justifyContent={"center"} sx={{ width: "100%" }}>
                      <Typography variant="h6" sx={{ fontWeight: "bold", wordWrap: "break-word" }}>
                        {" "}
                        {items?.titre}{" "}
                      </Typography>
                      <Typography variant="body2" sx={{ textAlign: "left", py: 1, wordWrap: "break-word" }}>
                        par {items?.auteur === "" || items?.auteur === null || items?.auteur === undefined || items?.auteur === "null" ? "ANSSI" : items?.auteur}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} sx={{ bgcolor: MAIN_GRAY, borderRadius: 5, mb: 2, width: "100%" }} spacing={1} alignItems={"center"} justifyContent={"center"}>
                      {/* <Typography variant="body2" sx={{ fontSize: "0.7rem", wordWrap: "break-word" }}>
                        Categorie : {items?.categorie ?? "Non classé"}
                      </Typography> */}
                      <Typography variant="body2" sx={{ fontSize: "0.7rem", color: FANCY_ORANGE, textAlign: "left", wordWrap: "break-word" }}>
                        <TimerOutlined sx={{ fontSize: "0.7rem" }} /> {formatDate2(items?.created_at)}{" "}
                      </Typography>
                    </Stack>{" "}
                    <div dangerouslySetInnerHTML={{ __html: items?.contenu }} />
                  </Stack>
                </Grid>
                <Grid item xs={12} md={3} lg={4} p={2} order={{ xs: 1, md: 2 }}>
                  <img src={getDefaultImage(items?.image)} alt={items?.image} style={{ borderRadius: 10, width: "100%", objectFit: "contain", aspectRatio: "16/9" }} />
                </Grid>
              </>
            )}
          </Grid>
        </>
      ) : (
        // tous les articles
        <>
          <Grid container spacing={8} sx={{ width: "100%" }}>
            {loading ? (
              <Typography variant="h6" sx={{ fontWeight: "bold", position: "absolute", top: "50%", left: "50%" }}>
                {" "}
                Chargement...
              </Typography>
            ) : (
              <>
                {Array.isArray(items) && items.length === 0 && (
                  <div style={{ position: "relative", width: "100%" }}>
                    <Typography variant="h6" sx={{ fontWeight: "bold", position: "absolute", top: "50%", left: "50%", transform: "translate(50%, -50%)", wordWrap: "break-word" }}>
                      {" "}
                      Aucun article{" "}
                    </Typography>
                  </div>
                )}

                {Array.isArray(items) &&
                  items?.map((item, index) => (
                    // Grid item
                    <Grid item xs={12} md={4} lg={4} sx={{ width: "100%" }} key={index}>
                      {/* Main */}
                      <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ width: "100%", bgcolor:main_black_082, p: 2, borderRadius: 3}}>
                        {/* Image */}
                        <img src={getDefaultImage(item?.image)} alt={item?.image} style={{ borderRadius: 10, width: "100%", height: "10rem", objectFit: "cover" }} />
                        {/* Title */}
                        
                        <Stack direction={ "row" } spacing={{ xs: 0, md: 1 }} alignItems={"center"} justifyContent={"space-between"} sx={{ width: "100%" }}>
                          <Stack direction={"row"} alignItems={"center"} spacing={2}>
                          <Typography variant="h6" sx={{ fontWeight: "bold", wordWrap: "break-word", color: "white", fontSize: "0.9rem" }}>
                            {" "}
                            {item?.titre.slice(0, 25)}
                            {item?.titre.slice(40) ? "..." : ""}
                          </Typography>
                          {/* <Typography variant="body2" sx={{ textAlign: "left", py: 1, wordWrap: "break-word" }}>
                            par {item?.auteur === "" || item?.auteur === null || item?.auteur === undefined || item?.auteur === "null" ? "ANSSI" : item?.auteur}
                          </Typography> */}
                          </Stack>
                          <Button onClick={() => navigate(ARTICLE_DETAIL_PATH, { state: { article_id: item?.id } })} sx={{ color: FANCY_ORANGE, ml: { xs: "auto", md: 0 } }}>
                              plus+
                            </Button>
                        </Stack>
                        {/* Content */}
                        {/* <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"} sx={{ width: "100%", height: "2rem", bgcolor: "white", p: 1, wordWrap: "break-word" }}> */}

                        {/* text */}
                        {/* <div style={{ width: "100%", }}>
                          <span style={{ textAlign: "left", marginRight: "auto", height:"100%" }}>
                            {removeTags(item?.contenu.slice(0, 50))} {removeTags(item?.contenu.slice(0, 100)).slice(50) ? "..." : ""}
                            {Array.isArray(item?.contenu) && item?.contenu.length > 79 && "..."}
                          </span>
                          </div> */}
                        {/* Categorie and date */}
                        {/* <Stack direction={{ xs: "column", md: "row" }} justifyContent={"space-between"} alignItems={"center"} sx={{ width: "100%" }}>
                            <Stack direction={"row"} sx={{ bgcolor: MAIN_GRAY, borderRadius: 5 }} spacing={1} alignItems={"center"} justifyContent={"flex-end"}> */}
                        {/* <Typography variant="body2" sx={{ fontSize: "0.7rem", wordWrap: "break-word" }}>
                                Categorie : {item?.categorie ?? "Non classé"}
                              </Typography> */}
                        {/* <Typography variant="body2" sx={{ fontSize: "0.7rem", color: FANCY_ORANGE, wordWrap: "break-word" }}>
                                <TimerOutlined sx={{ fontSize: "0.7rem" }} /> {formatDate2(item?.created_at)}{" "}
                              </Typography>
                            </Stack> */}
                        {/* see more */}
                        {/* <Button onClick={() => navigate(ARTICLE_DETAIL_PATH, { state: { article_id: item?.id } })} sx={{ color: FANCY_ORANGE, ml: { xs: "auto", md: 0 } }}>
                              plus+
                            </Button>{" "}
                          </Stack>
                        </Stack> */}
                      </Stack>
                    </Grid>
                  ))}
              </>
            )}
          </Grid>
          <Stack direction={"row"} justifyContent={"flex-end"} alignItems={"center"} my={3} sx={Array.isArray(items) && items.length === 0 ? { position: "absolute", top: "90%", left: "50%", transform: "translate(50%, -50%)" } : {}}>
            <Pagination count={Math.ceil((count ?? 0) / (rowsPerPage ?? 6))} variant="outlined" shape="rounded" color="primary" onChange={onChangePage} page={page} />
          </Stack>
        </>
      )}
    </Stack>
  );
}
