import { IconButton, Stack, Typography } from "@mui/material";
import "./input.css";
import { RemoveRedEye } from "@mui/icons-material";
import { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Email } from "@mui/icons-material";
import { Phone } from "@mui/icons-material";


export default function CheckBox(prop) {
  const {  width = "100%", type, required, value, defaultValue, onChange,  disabled, style, label, error, name, id, inputRef, options } = prop;


  return (
    <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"}>
      <div className="frame-1000002056-2" style={{ ...style }}>
      <input  type={"checkbox"} required={required} value={value} defaultValue={defaultValue} onChange={onChange} disabled={disabled} className={"_00-00-00-00-00-2"} name={name} id={id} />
        
        <div className="frame-1000002030-2">
          <div className="email-ou-num-ro-de-t-l-phone-2"> {label} </div>
        </div>
      </div>

      <Typography variant="body1" sx={{ color: "red" }}>
        {" "}
        {error}{" "}
      </Typography>
    </Stack>
  );
}
