import axios from "axios";
import KjosHttp from "../controller/KjosHttp";
import { BASE_URL_API, IS_AUTH_URL } from "../config/RoutesUrl";
import useLocalSorage from "./useLocalSorage";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setIsAuth } from "../store/AuthenticationReducer";
import { DASHBOARD_PATH, RESET_PASSWORD_CONFIRM_CODE_PATH, RESET_PASSWORD_CONFIRM_PATH, RESET_PASSWORD_PATH, SIGNIN_PATH, SIGNUP_CONFIRM_PATH, SIGNIN_CONFIRM_PATH, SIGNUP_INFORMATION_PATH, SIGNUP_PASSWORD_PATH, SIGNUP_PATH, POLITIQUE_PATH, CONDITION_UTILISATION_PATH } from "../config/PathUrl";

function useIsAuthenticate(url = BASE_URL_API + IS_AUTH_URL, item = "user", route = {signin: SIGNIN_PATH, signup: SIGNUP_PATH, resetPassword: RESET_PASSWORD_PATH, resetPasswordConfirm: RESET_PASSWORD_CONFIRM_PATH, singupConfirm: SIGNUP_CONFIRM_PATH,signinConfirm: SIGNIN_CONFIRM_PATH, signupInformation : SIGNUP_INFORMATION_PATH, signupPassword: SIGNUP_PASSWORD_PATH, politique: POLITIQUE_PATH, condition: CONDITION_UTILISATION_PATH}, dashboard = DASHBOARD_PATH, resetPasswordConfirmCode = RESET_PASSWORD_CONFIRM_CODE_PATH) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  
  // This function will check if user is authenticated
  const isAuthUser = useCallback(async () => {
    const navigate = useNavigate(); // use navigate to redirect from a page to another
    const [getLocalStorage, removeLocalStorage] = useLocalSorage(); // get local storage hook
    const location = useLocation(); // use location for the name of the route
    const token = getLocalStorage(item)?.token; // get token
    const config = { headers: { Accept: "application/json", Authorization: `Bearer ${token}` } }; // get config
    try {
      const isAuth = await new KjosHttp().get(url, config); // ping the route
      // 
      if (isAuth?.error?.response.status === 401 && (location.pathname !== route.signin && location.pathname !== route.signup && location.pathname !== route.resetPassword && location.pathname !== route.resetPasswordConfirm && location.pathname !== resetPasswordConfirmCode && location.pathname !== route.singupConfirm && location.pathname !== route.signinConfirm && location.pathname !== route.signupInformation && location.pathname !== route.signupPassword && location.pathname !== "/" && location.pathname !== route.politique && location.pathname !== route.condition)) {
        // navigate(route); // redirect to signin
        // if there is an error remove the user
        removeLocalStorage(item); // remove the user
        setIsAuthenticated(false); // set auth to false if user is not connected
        navigate(route.signin); // redirect to signin
      } else if (isAuth?.status === 200 && (location.pathname === route.signin || location.pathname === route.signup || location.pathname === route.resetPassword || location.pathname === "/" || location.pathname === route.singupConfirm || location.pathname === route.signinConfirm || location.pathname === route.signupInformation || location.pathname === route.signupPassword || location.pathname === route.politique || location.pathname === route.condition)) {
        navigate(dashboard); // redirect to dashboard
        setIsAuthenticated(false); // set auth to true if user is connected
      } else {
        setIsAuthenticated(true); // set auth to true if user is connected
      }
    } catch (error) {
      setIsAuthenticated(false);
    }
  }, [url, item, route, dashboard, location, resetPasswordConfirmCode]);


  async function fetchData(){
    await isAuthUser();
   }
   fetchData();

    // await isAuthUser();
    return {isAuthenticated, isAuthUser};
  }

export default useIsAuthenticate;
