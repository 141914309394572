import { Link } from "react-router-dom";

export default function KjosBackIconWithLabel(props) {
   return (
      <Link className="text-decoration-none" to={props.to}>
      <div className={`d-flex ${props.display} justify-content-center align-items-center`}>
         <div style={{ width: props.width, height: props.height, color: props.colorIcon }} > {props.icon} </div> 
         <div style={{ fontSize: props.fontSizeLabel, color: props.colorLabel, fontWeight: props.fontWeightLabel }} > {props.label} </div>
      </div>
      </Link>
   );
}