export default function getCurrentDate(format, date = null){
      // Check if a format is provided
      if (format) {
        const currentDate = date ? new Date(date) : new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    
        // Replace format placeholders with corresponding date and time values
        let formattedDate = format.replace('y', year);
        formattedDate = formattedDate.replace('m', month);
        formattedDate = formattedDate.replace('d', day);
        formattedDate = formattedDate.replace('h', hours);
        formattedDate = formattedDate.replace('m', minutes);
        formattedDate = formattedDate.replace('s', seconds);
    
        // Return the formatted date
        return formattedDate;
      } else {
        // If no format is provided, return the current date and time in the default format
        const currentDate = date ? new Date(date) : new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const day = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        const seconds = String(currentDate.getSeconds()).padStart(2, '0');
    
        const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    
        // Return the current date and time
        return currentDateTime;
      }
    }