export const BASE_URL = "https://api-gds.radiangn.com";
export const BASE_URL_API = BASE_URL + "/api/v1";
export const LOGIN_URL = "/login";
export const REGISTER_URL = "/register";
export const LOGOUT_URL = "/user/logout";
export const CSRF_COOKIES = "/sanctum/csrf-cookie";
export const IS_AUTH_URL = "/user/isauth";
export const CLASSE_SIGNALEMENT_CREATE_URL = BASE_URL_API + "/admin/classes_signalement";
export const CLASSE_SIGNALEMENT_GET_URL = BASE_URL_API + "/admin/classes_signalement";
export const ANONYME_SIGNALEMENT_GET_URL = BASE_URL_API + "/classes_signalement";
export const SOURCE_DE_DONNEE_URL = BASE_URL_API + "/admin/sources";
export const ANONYME_SOURCE_DE_DONNEE_URL = BASE_URL_API + "/sources";
export const USER_URL = BASE_URL_API + "/user/users";
export const USER_USER_URL = BASE_URL_API + "/admin/admins";
export const USER_SIGNALLEMENT_URL = BASE_URL_API + "/user/signalements";
export const ANONYME_SIGNALLEMENT_URL = BASE_URL_API + "/signalements"; 
export const USER_SIGNALLEMENT_USER_URL = BASE_URL_API + "/admin/signalements/user";
export const USER_SIGNALLEMENT_QUERY_URL = BASE_URL_API + "/user/signalements_query";
export const ANONYME_SIGNALLEMENT_QUERY_URL = BASE_URL_API + "/signalements_query";
export const USER_TYPE_SIGNALEMENT_URL = BASE_URL_API + "/user/types_signalement";
export const ANONYME_TYPE_SIGNALEMENT_URL = BASE_URL_API + "/types_signalement";
export const USER_HABILETE_URL = BASE_URL_API + "/admin/habiletes";
export const USER_CIRCUIT_TRAITEMENT_URL = BASE_URL_API + "/admin/circuit_traitement";
export const USER_HISTORIQUE_URL = BASE_URL_API + "/user/historique";
export const ANONYME_HISTORIQUE_URL = BASE_URL_API + "/historique";
export const USER_INDEX_TRAITEMENT = BASE_URL_API + "/user/index_traitement";
export const ANONONYME_INDEX_TRAITEMENT = BASE_URL_API + "/index_traitement";
export const USER_SOURCE_URL = BASE_URL_API + "/user/sources";
export const USER_ADMIN_ACTION_URL = BASE_URL_API + "/user/adminactions";
export const ANONYME_SOURCE_URL = BASE_URL_API + "/sources";
export const ANONYME_ADMIN_ACTION_URL = BASE_URL_API + "/adminactions";

export const INIT_PASSWORD_URL = BASE_URL_API + "/init/password";
export const FORGET_PASSWORD_URL = BASE_URL_API + "/forget/password";
export const VERIFY_CODE_URL = BASE_URL_API + "/verify/code";
export const RESEND_CODE_URL = BASE_URL_API + "/resend/code";
export const SIGNUP_CONFIRM_URL = BASE_URL_API + "/register/signupConfirm";
export const SIGNIN_CONFIRM_URL = BASE_URL_API + "/signin/signinConfirm";
export const SIGNIN_CONFIRM_RECAPTCHA_URL = BASE_URL_API + "/signin/signinConfirm/recaptcha";
export const SIGNUP_VERIFY_CODE_URL = BASE_URL_API + "/register/verifyCode";
export const SIGNUP_RESEND_CODE_URL = BASE_URL_API + "/register/rensendCode";
export const USER_COMMUNE_URL = BASE_URL_API + "/user/communes";
export const USER_GET_ADMIN_BY_PERMISSION = BASE_URL_API + "/user/index_user_for_permissions";
export const USER_PLAINTE_URL = BASE_URL_API + "/user/plaintes";
export const ARTICLE_URL = BASE_URL_API + "/articles";
export const CMS_GET = BASE_URL_API + "/cms/get";



export const USER_CLASSE_SIGNALEMENT_GET_URL = BASE_URL_API + "/user/classes_signalement";

