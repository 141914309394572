import { IconButton, Stack, Typography } from "@mui/material";
import "./input.css";
import { RemoveRedEye } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Email } from "@mui/icons-material";
import { Phone } from "@mui/icons-material";

export default function InputRectangle(prop) {
  const { placeholder, width, type, required, value, defaultValue, onChange, onKeyPress, disabled, style, label, emailPhone, error, name, id, inputRef, callBack } = prop;
  const inputRefs = useRef([...Array(6)].map(() => React.createRef()));
  const [inputValues, setInputValues] = useState(Array(6).fill(''));


  const handleKeyDown = (index, event) => {
    

    if (event.key === "Backspace" && event.target.value === "") {
      if (index > 0) {
        inputRefs.current[index - 1].current.focus();
      }
      
    }
  };

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    setInputValues(prevInputValues => {
      const newInputValues = [...prevInputValues];
      newInputValues[index] = value;
    callBack(newInputValues.join(''))
    return newInputValues;
    });

    if (index < inputRefs.current.length - 1 && event.target.value.length === 1) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  return (
    <Stack direction="row" justifyContent={"space-between"} spacing={2}>
      {[...Array(6)].map((_, index) => (
        <input placeholder={placeholder} type={"text"} required={required} value={value} defaultValue={defaultValue} disabled={disabled} className={"rectangle_62"} name={name} id={id} autoComplete="off" key={index} ref={inputRefs.current[index]} onKeyDown={(event) => handleKeyDown(index, event)} onChange={(event) => handleInputChange(index, event)} maxLength={1}
        />
      ))}
    </Stack>
  );
}
