import axios from "axios";
import { BASE_URL, BASE_URL_API, CSRF_COOKIES, FORGET_PASSWORD_URL, INIT_PASSWORD_URL, LOGIN_URL, RESEND_CODE_URL, SIGNUP_CONFIRM_URL, VERIFY_CODE_URL } from "../config/RoutesUrl";
import { useDispatch } from "react-redux";
import { setAuth, seterror, setloading } from "../store/AuthenticationReducer";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useLocalSorage from "./useLocalSorage";
import { DASHBOARD_PATH, SIGNUP_CONFIRM_PATH, SIGNUP_INFORMATION_PATH } from "../config/PathUrl";
import { setAlert } from "store/SignalementReducer";
import useHttp from "./useHttp";

function useAuthenticate(tokenUrl = BASE_URL + CSRF_COOKIES, loginUrl = BASE_URL_API + LOGIN_URL) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getLocalStorage, removeLocalStorage, setLocalStorage] = useLocalSorage();
  const { getData, postData } = useHttp();
  const handleFormSubmit = useCallback(
    async (data, action = DASHBOARD_PATH) => {
      // param.preventDefault(); // prevent default
      // dispatch(setloading(true)); // start loader
      // const formData = new FormData(param.currentTarget); // get form data
      // const data = {
      //   email: formData.get("email"),
      //   password: formData.get("password"),
      //   role: "user",
      // }; // get form data
      const email = data?.email;
      const type = data?.type;
      const makeSignalement = data?.makeSignalement;

      axios.defaults.withCredentials = true;
      try {
        await axios.get(tokenUrl); // ping the route
        const request = await axios.post(loginUrl, data); // get user
        const response = request;
        

        if (response?.data?.error) {
          // if user exists continue else create
          if (response?.data?.message === "NOT_FOUND_USER") {
            navigate(SIGNUP_INFORMATION_PATH, { state: { email: email, type: type, nextPage: true, makeSignalement: makeSignalement } });
          }

          dispatch(seterror(response?.data?.error)); // save error
          dispatch(setAuth(response?.data?.data ?? response?.data)); // save error data
          let data = response?.data;
          let message;

          if (data?.validator) {
            Object.keys(data?.data)?.map((key) => {
              message = (data?.data[key]?.map((item) => item))[0];
            });
          } else {
            message = data?.message;
          }
          dispatch(
            setAlert({
              open: true,
              message: message,
              color: "rgba(254, 0, 0, 1)",
            })
          );
          return response?.data?.data ?? response?.data;
        } else {
          setLocalStorage("user", response?.data?.data);
          dispatch(setAuth(response?.data?.data)); // save user in state
          navigate(action);
        }
        // dispatch(setloading(false)); // stop loader
      } catch (error) {
        // dispatch(seterror({ serverError: true, error: error }));
        
      }
    },
    [dispatch, loginUrl, tokenUrl]
  );

  return [handleFormSubmit];
}

export default useAuthenticate;
