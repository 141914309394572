import { Box, Button as ButtonMui, Container, Grid, Stack, SvgIcon, Tooltip, Typography } from "@mui/material";
import { bg_black, main_blue, main_blue2 } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input from "../../component/input/Input2";
import InfoIcon from "@mui/icons-material/Info";
import TextArea from "../../component/textarea/TextArea";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_ADMIN_ACTION_URL, USER_COMMUNE_URL, USER_INDEX_TRAITEMENT, USER_SIGNALLEMENT_URL, USER_SOURCE_URL, USER_TYPE_SIGNALEMENT_URL } from "../../config/RoutesUrl";
import useHttp from "../../hooks/useHttp";
import HeaderConnecte from "component/header/HeaderConnecte";
import KjosHeader from "component/KjosHeader";
import Footer from "component/footer/Footer";
import { SIGNALEMENT_CREATE_FIN_PATH, SIGNALEMENT_CREATE_SUIVANT_PATH } from "config/PathUrl";
import KjosNextPage from "component/KjosNextPage";
import InputFile from "component/file/InputFile";
import FileIcon from "component/buttons/icons/FileIcon";
import InputDate from "component/input/InputDate";
import useUser from "hooks/useUser";
import { useDispatch } from "react-redux";
import { setAlert } from "store/SignalementReducer";
import useHistorique from "hooks/useHistorique";
import Select from "component/input/Select";

const elemen_en_cause_title = "Entité, Personne mise en cause, Email, Url, numéro de téléphone: AVERTISSEMENT: Ne pas indiquer *SMS*, *téléphone* ou *mail*. Si vous avez reçu un message sur ces supports, il contient surement un lien vers internet. Dans ce cas veuillez choisir *sur un site web* à l'étape précédente puis copiez le lien (http://...) dans le champ URL. A défaut de lien internet, indiquez le numéro de téléphone ou l'adresse de courriel de l'expéditeur. Sachez que dans ce cas nos services seront incapables de constater les faits.Indiquez le lien internet (URL) correspondant au contenu que vous signalez, tel qu'il apparaît dans la barre d'adresse de votre navigateur. N'indiquez qu'un seul lien (Si vous désirez indiquer un autre lien merci de procéder à un nouveau signalement).Indiquez l'adresse email à l'origine du contenu illicite (expéditeur). N'indiquez qu'une seule adresse. Ne pas indiquer votre propre email.";

export default function CreateSuivantSignalement() {
  const { getUser } = useUser();
  const { setHistorique } = useHistorique();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const { getData, postData, putData, deleteData } = useHttp(); // get data from server
  const navigate = useNavigate();
  const [commune, setCommune] = useState([]);

  const location = useLocation();

  const fileListRef = useRef();
  const errorFileListRef = useRef();
  const refImage = useRef();
  const mainImageRef = useRef();

  const dispatch = useDispatch();
  let interval;
  const handleLoadingMessage = () => {
    if (loading) {
      const i = 2000;
      interval = setInterval(() => {
        // if elapsed time is multiple of 2000, update the message
        if (i % 2000 === 0) {
          setLoadingMessage("Envoie...");
        } else {
          setLoadingMessage("Veuillez patienter...");
        }
        i = i + 2000;
      }, 2000);
    }
  };
  const handleForward = async (e) => {
    e.preventDefault();
    setLoading(true);
    handleLoadingMessage;
    const locationData = location.state?.data;
    const data = JSON.parse(locationData);

    const formData = new FormData(e.currentTarget);
    const formData2 = new FormData();
    formData2.append("type_signalement_id", data?.type_signalement_id);
    formData2.append("source_id", data?.source_id);
    formData2.append("source_signalement_id", data?.source_id);
    formData2.append("autre_sources", data?.autre_sources);
    formData2.append("commune_id", formData.get("commune_id"));
    formData2.append("element_en_cause", formData.get("element_en_cause"));
    formData2.append("type", "neant");
    formData2.append("description", formData.get("description"));
    formData2.append("statut", "new");
    formData2.append("user_id", getUser("user")?.user?.id);
    formData2.append("habilete_position", 0);

    Array.from(mainImageRef.current.files).forEach((file) => formData2.append("preuve[]", file));

    const creatSign = await postData(formData2, USER_SIGNALLEMENT_URL);
    
    if (creatSign?.error) {
      dispatch(setAlert({ open: true, message: creatSign?.message, color: "red" }));
    } else {
      const habilete_position = creatSign?.data?.habilete_position;
      const habiletes = creatSign?.data?.classe?.habiletes;
      // const historique = await setHistorique("Vous avez créé un signalement", creatSign?.data?.id, null, ANONYME_HISTORIQUE_URL)
      if (Array.isArray(habiletes)) {
        // Index traitement
        if (habilete_position < habiletes.length - 1) {
          // const current_habiletes = habiletes[habilete_position + 1]; //code original
          const current_habiletes = habiletes[habilete_position]; //code de test

          if (Array.isArray(current_habiletes)) {
            Promise.all(
              current_habiletes.map(async (item) => {
                const data = {
                  habilete_id: item,
                  signalement_id: creatSign?.data?.id,
                };
                const data2 = {
                  habilete_id: item,
                  signalement_id: creatSign?.data?.id,
                  action: "create",
                };
                const t = await postData(data, USER_INDEX_TRAITEMENT);
                // Admin action
                const adminAction = await postData(data2, USER_ADMIN_ACTION_URL);
              })
            );
          } else {
            const data = {
              habilete_id: current_habiletes,
              signalement_id: creatSign?.data?.id,
            };
            const data2 = {
              habilete_id: current_habiletes,
              signalement_id: creatSign?.data?.id,
              action: "create",
              first_create: true,
            };
            await postData(data, USER_INDEX_TRAITEMENT);
            // Admin action
            await postData(data2, USER_ADMIN_ACTION_URL);
          }
        }
      }
      // const historique = await setHistorique("Vous avez créé un signalement", creatSign?.data?.id, null);
      // Historique
      const response = await setHistorique("new", creatSign?.data?.id, getUser("user")?.user?.id, "L'utilisateur " + getUser("user")?.user?.nom + " a créé le signalement: ");

      setLoading(false);

      navigate(SIGNALEMENT_CREATE_FIN_PATH, {
        state: {
          code: creatSign?.data?.code,
          nextPage: true,
        },
      });
    }
  };

  useEffect(() => {
    getData(USER_COMMUNE_URL).then((data) => {
      const com = data?.data?.map((item) => ({
        label: item.libelle,
        value: item.id,
        id: item.id,
      }));
      setCommune(com);
    });
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <KjosNextPage>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
      >
        <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>
          <KjosHeader>Créer un signalement | ANSSI</KjosHeader>

          <HeaderConnecte />
          <form onSubmit={handleForward} style={{ width: "100%", height: "100%" }}>
            <Stack direction={"column"} sx={{ height: "100%", width: "100%", px: { xs: 1, md: 8 }, py: 2 }}>
              <Typography variant="h5">Parcours de signalement</Typography>
              <br />

              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid container spacing={2} gap={2}>
                    {/* <Grid item xs={12}>
                      <InputDate
                        label={
                          <Typography sx={{ color: "#8e9198" }} variant="body2">
                            Date des faits
                          </Typography>
                        }
                        type="date"
                        width="100%"
                        name="date"
                        max={new Date().toISOString().split("T")[0]}
                        required
                      />
                    </Grid> */}

                    {/* <Grid item xs={12}>
                    
                      <Select label="Lieu des faits" options={commune} width="100%" name="commune_id" required />

                    </Grid> */}

                    <Grid item xs={12}>
                      <Input
                        label={
                          <Stack direction="row" alignItems={"center"}>
                            <Typography sx={{ color: "#8e9198" }} variant="body2">
                              Elément en cause
                            </Typography>{" "}
                            <Tooltip title={elemen_en_cause_title}>
                              <SvgIcon>
                                <InfoIcon />
                              </SvgIcon>
                            </Tooltip>{" "}
                          </Stack>
                        }
                        type="text"
                        width="100%"
                        name="element_en_cause"
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <InputFile
                        width="100%"
                        height="5rem"
                        borderRadius="6px"
                        component={<FileIcon style={{ position: "absolute", top: "50%", left: "95%", transform: "translate(-50%, -50%)" }} />}
                        accept=".pdf, .txt, .docx, .xlsx, .jpg, .png"
                        id="evidence"
                        name="evidence"
                        border={`${"1px solid #8e9198"}`}
                        label="cliquer pour ajouter une pièce jointe"
                        fileListRef={fileListRef}
                        errorFileListRef={errorFileListRef}
                        multiple={true}
                        refImage={refImage}
                        printImage={true}
                        mainImageRef={mainImageRef}
                        labelTop={
                          <Typography sx={{ color: "#8e9198" }} variant="body2">
                            Joindre une preuve (Pdf, txt, docx, xlsx, jpg, png)
                          </Typography>
                        }
                      />

                      <div className="mt-2 bg-blue-green d-flex flex-column justify-content-center align-items-start">
                        <div ref={errorFileListRef}></div>
                        <div ref={fileListRef}></div>
                      </div>

                      <div ref={refImage} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextArea
                        label={
                          <Typography sx={{ color: "#8e9198" }} variant="body2">
                            Description des faits
                          </Typography>
                        }
                        name="description"
                        required
                      ></TextArea>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <br />

              <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
                <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)}>
                  Précédent
                </ButtonMui>

                <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                  Suivant
                </ButtonMui>
                {loading && (
                  <div className="spinner-border" role="status" style={{ color: main_blue }}>
                    <span className="sr-only"></span>
                  </div>
                )}
              </Stack>
            </Stack>
          </form>
        </Stack>
      </Box>
      <Footer />
    </KjosNextPage>
  );
}
