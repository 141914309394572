import KjosHeader from "component/KjosHeader";
import { Box, Button, Container, Stack, SvgIcon } from "@mui/material";
import { FANCY_BLUE } from "config/Color";
import DescriptionIcon from "@mui/icons-material/Description";
import { useLocation, useNavigate } from "react-router-dom";
import Article from "component/articles/Article";
import useHttp from "hooks/useHttp";
import { useEffect, useState } from "react";
import { ARTICLE_URL } from "config/RoutesUrl";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import HeaderConnecte from "component/header/HeaderConnecte";
import Footer from "component/footer/Footer";
import useUser from "hooks/useUser";
import Header from "component/header/Header";

const DetailArticle = () => {
  const location = useLocation();
  const article_id = location.state?.article_id;
  const { getOneData } = useHttp();
  const [article, setArticle] = useState({});
  const navigate = useNavigate();
  const { getUser } = useUser();
  useEffect(() => {
    getOneData(article_id, ARTICLE_URL).then((data) => {
      
      setArticle(data.data);
    });
  }, [getOneData]);
  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
      >
        <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>
          <KjosHeader>Détail Article | ANSSI</KjosHeader>
          {getUser("user") ? <HeaderConnecte /> : <Header />}

          <Button className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start", m: 2 }} onClick={() => navigate(-1)}>
            Retour
          </Button>
          <Stack spacing={3} p={3}>
            <Article items={article} detail />
          </Stack>
        </Stack>
      </Box>
      <Footer />
    </>
  );
};

export default DetailArticle;
