import { Box, Button as ButtonMui, Container, Grid, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import { FANCY_BLUE, main_blue, main_gray } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input2 from "../../component/input/Input2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FORGET_PASSWORD_URL } from "../../config/RoutesUrl";
import Button from "../../component/buttons/Button";
// import ButtonSmall from "../../component/buttons/ButtonSmal";
import useIsAuthenticate from "../../hooks/useIsAuthenticate";
import { useDispatch, useSelector } from "react-redux";
import useAuthenticate from "../../hooks/useAuthenticate";
import { useEffect, useState } from "react";
import { setAuth } from "../../store/AuthenticationReducer";
import { setAlert } from "../../store/SignalementReducer";
import KjosAlert from "../../component/KjosAlert";
import KjosNextPage from "../../component/KjosNextPage";
import { SIGNIN_PATH, SIGNUP_PASSWORD_PATH } from "../../config/PathUrl";
import detectEmailOrNumber from "controller/detectEmailOrNumber";
import useRegister from "../../hooks/useRegister";
import InputDate from "../../component/input/InputDate";
import Select from "../../component/input/Select";
import CheckBox from "../../component/input/CheckBox";

export default function SignupPassword() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { auth, loading, error } = useSelector((state) => state.authentication);
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
  const location = useLocation();
  const [code, setCode] = useState("");
  const [ handleFormSubmit ] = useRegister();
  const [resend, setResend] = useState(false);
  const value = (input) => {
    
    setCode(input);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const stateData = JSON.parse(location?.state?.data);
    const type = detectEmailOrNumber(stateData?.email);
    const formData = new FormData(e.currentTarget); // get form data
    const password = formData.get("password");
    const password_confirmation = formData.get("password_confirmation");
    if (password !== password_confirmation) { 
      dispatch(
        setAlert({
          open: true,
          message: "Les mots de passe ne sont pas identiques",
          color: "rgba(254, 0, 0, 1)",
        })
      );
      return;
    }

    const data = {
      ...stateData,
      password: password,
      password_confirmation: password_confirmation,
      role: "user",
      type: type
    }; // get fo
console.log(data)
    await handleFormSubmit(data, SIGNIN_PATH, false);
    
 };


  useEffect(() => {
    return () => {
      dispatch(setAuth({}));
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, [dispatch]);

  return (
    <KjosNextPage>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <form onSubmit={handleSubmit} style={{ height: "100%" }}>
          <KjosAlert label={alert.message} />

          {isSmallScreen && (
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={{ xs: 3, md: 6 }} sx={{ height: { md: "100%", width: "100%" } }}>
              <Logo />
              <Box sx={{ marginY: { md: "auto" } }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: {
                      xs: "1rem",
                      color: main_blue,
                    },
                    textAlign: "center",
                  }}
                >
                  Bienvenue dans la version web de ANSSI
                </Typography>
                <Typography variant="h6" sx={{ fontSize: { xs: "0.8rem" } }}>
                  Vous souhaitez signaler un contenu illicite de l’internet ?
                </Typography>
              </Box>
            </Stack>
          )}
          <Grid container sx={{ height: "100%" }}>
            {isMediumScreen && (
              <Grid item xs={12} md={6} sx={{ bgcolor: { md: main_gray }, height: { md: "100%" }, p: 0 }}>
                <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"} p={{ xs: 3, md: 6 }} sx={{ height: { md: "100%" } }}>
                  <Logo />
                  <Box sx={{ marginY: { md: "auto" } }}>
                    <Typography
                      variant="h1"
                      sx={{
                        fontSize: {
                          xs: "1.5rem",
                          md: "5rem",
                          color: main_blue,
                        },
                      }}
                    >
                      Bienvenue dans la <br /> version web de <br /> ANSSI
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: { xs: "1rem", md: "1.5rem" } }}>
                      Vous souhaitez signaler <br /> un contenu illicite de l’internet ?
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            )}

            <Grid item xs={12} md={6} sx={{ height: { md: "100%" } }}>
              <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={6} sx={{ height: { md: "100%" } }}>
                <Stack direction={"row"} justifyContent={"center"} spacing={2} alignItems={"center"} sx={{ width: "100%" }}>
                  <Typography variant="body1"> Avez vous déjà un compte? </Typography>
                  <ButtonMui
                    className="rectangle-3"
                    variant="contained"
                    sx={{ alignSelf: "flex-start" }}
                    onClick={() => {
                      navigate(SIGNIN_PATH);
                    }}
                  >
                    Se connecter
                  </ButtonMui>
                  {/* <button className="rectangle-3"> Inscription </button> */}
                </Stack>
                <Box sx={{ marginY: { md: "auto" } }}>
                  <Typography
                    variant="h1"
                    sx={{
                      fontSize: {
                        xs: "2rem",
                        sm: "3rem",
                        md: "4rem",
                        color: main_blue,
                      },
                    }}
                  >
                    Créer un mot <br /> de passe
                  </Typography>

                  <br />

                  <Input2 name="password" label="Mot de passe" type="password" />
                  <br />
                  <Input2 name="password_confirmation" label="Confirmation" type="password" />
                  

                  <br />
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <CheckBox name="condition" required />
                    <Typography variant="body2">J'accepte les  <Link sx={{ color: "#2b91ff", textDecoration: "none" }} to={"/condition-utilisation"}>conditions générales</Link> d'utilisation et la <Link sx={{ color: "#2b91ff", textDecoration: "none" }} to={"/condition-utilisation"}>politique de confidentialité</Link> </Typography>
                  </Stack>
                </Box>

                {/* <ButtonSmall title="Suivant" sx={{alignSelf:"flex-start"}} type="submit" /> */}
                <Stack direction="row" spacing={2}>
                  <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)}>
                    Retour
                  </ButtonMui>

                  <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                    Suivant
                  </ButtonMui>
                </Stack>

                {/* Copyright */}
                <Typography variant="body1" sx={{ color: main_blue, marginTop: "auto", textAlign: "center" }}>
                  Copyright @ ANSSI 2023
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </Box>
    </KjosNextPage>
  );
}
