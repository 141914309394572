import { avatar_anonyme } from "../config/Image";
import { BASE_URL } from "../config/RoutesUrl";

export default function getDefaultImage(image, defaultImage=null) {
   console.log(image)

   if (
      image === null ||
      image === "" ||
      image === undefined ||
      image === "null" ||
      image === "undefined" ||
      image === "" ||
      image === " " ||
      image === "/images/profil/avatar_anonyme_boy.jpg"
    ) {
    return (defaultImage ?? avatar_anonyme)

   }

   if (image.startsWith("/")) {
    image = image.substring(1);
  }
  
   return  BASE_URL + "/" + image;
}