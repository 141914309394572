import { Link, useNavigate } from "react-router-dom";
import Button from "component/buttons/Button";
import KjosHeader from "component/KjosHeader";
import FinanceIcon from "component/buttons/icons/FinanceIcon";
import Logo from "component/logo/Logo";
import { SIGNIN_PATH } from "config/PathUrl";
import { Box, Container, Stack, Typography } from "@mui/material";
import Header from "component/header/Header";
import Footer from "component/footer/Footer";
import Article from "component/articles/Article";
import { main_gray } from "config/Color";
import HeaderConnecte from "component/header/HeaderConnecte";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { ARTICLE_URL, CMS_GET } from "config/RoutesUrl";
import useHttp from "hooks/useHttp";
import { setCount, setLoading, setPage } from "store/SignalementReducer";
import Image from "component/logo/Image";
import { logo } from "config/Image";
export default function dashboard() {
  const { page, rowsPerPage, alert, count, loading } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
  const [article, setArticle] = useState([]);
  const { getData } = useHttp();
  const dispatch = useDispatch();
  const mainRef = useRef();
  const [stackHeight, setStackHeight] = useState("100%");
  const onChangePage = (event, newPage) => {
    dispatch(setPage(newPage));
    
  };

  useEffect(() => {
    getData(ARTICLE_URL + `?page=${page}&per_page=${3}&order_by=created_at&order=desc`).then((data) => {
      if (data?.error) {
        return;
      }
      
      dispatch(setCount(data?.count));
      setArticle(data?.data);
      dispatch(setLoading(false)); // setLoading
    });
  }, [dispatch, page, rowsPerPage, count]);

  useEffect(() => {
    if (mainRef.current) {
      const mainRefHeight = mainRef.current.offsetHeight;
      setStackHeight(mainRefHeight - 200 + "px");
    }
  }, []);

  const defaultSiteContent = (
    <>
      {/* Sur Internet aussi, */} {/* Vous pouvez */} {/* Vous être Témoin ou victime d'une infraction. Quand je rencontre des contenus nuisibles en ligne, tels que l'apologie du terrorisme, la diffamation, ou l'incitation à la haine, ma réponse est claire : je signale ces infractions à ANSSI pour un Internet plus sûr. */}{" "}
    </>
  );

  const [cms, setcms] = useState({});
  useEffect(() => {
    getData(CMS_GET).then((data) => {
      if (data?.error) {
        return;
      }
      setcms(data?.data);
    });
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
        ref={mainRef}
      >
        <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>
          <KjosHeader>Accueil | ANSSI</KjosHeader>

          <HeaderConnecte />

          <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ p: 0, width: "100%" }}>
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ width: "100%", px: 0, pt: 2 }} spacing={1}>

              <Stack direction={"row"} justifyContent={"space-between"}>                
                <Stack sx={{ pt: { md: 2 } }}>
                  <Typography variant="h6" sx={{ wordWrap: "break-word", px: { xs: 2, md: 25 }, textAlign: "center" }}>
                    {cms?.body?.welcome_text ? <div dangerouslySetInnerHTML={{ __html: cms?.body?.welcome_text.replace(/<\/?p>/g, "") }}></div> : defaultSiteContent}
                  </Typography>
                </Stack>
              </Stack>

              {/* Articles */}
              <Article items={article} count={count} page={page} onChangePage={onChangePage} loading={loading} rowsPerPage={3} />
            </Stack>
          </Stack>
        </Stack>
      </Box>
      <Footer fixed={false} />
    </>
  );
}
