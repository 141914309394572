import { IconButton, Stack, Typography } from "@mui/material";
import "./input.css";
import { RemoveRedEye } from "@mui/icons-material";
import { useEffect, useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Email } from "@mui/icons-material";
import { Phone } from "@mui/icons-material";
import openJson from "controller/openJson";

export default function InputText(prop) {
  const { placeholder, width = "100%", type, required, value, defaultValue, onChange, disabled, style, label, error, name, id} = prop;

  return (
    <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"}>
      <div className="frame-1000002056" style={{ ...style, width: width }}>
        <input placeholder={placeholder} type={type} required={required} value={value} defaultValue={defaultValue} onChange={onChange} disabled={disabled} className={"_00-00-00-00-00"} name={name} id={id} autoComplete="off" />

        {/*           
          <IconButton sx={{ position: "absolute", right: "10px", top: "50%", transform: "translateY(-50%)" }}><Email /></IconButton> */}

        <div className="frame-1000002030">
          <div className="email-ou-num-ro-de-t-l-phone"> {label} </div>
        </div>
      </div>
      <Typography variant="body1" sx={{ color: "red" }}>
        {" "}
        {error}{" "}
      </Typography>
    </Stack>
  );
}
