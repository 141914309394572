import { Box, Button as ButtonMui, Container, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { FANCY_GREEN, bg_black, main_blue2 } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input from "../../component/input/Input2";
import Select from "../../component/input/Select";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { USER_COMMUNE_URL, USER_GET_ADMIN_BY_PERMISSION, USER_SOURCE_URL, USER_TYPE_SIGNALEMENT_URL } from "../../config/RoutesUrl";
import useHttp from "../../hooks/useHttp";
import HeaderConnecte from "component/header/HeaderConnecte";
import KjosHeader from "component/KjosHeader";
import Footer from "component/footer/Footer";
import Rectangle from "component/rectangle/Rectangle";
import { PRE_PLAINTE_IDENTITE_PATH } from "config/PathUrl";
import KjosYMaps from "component/KjosYMaps";
import { Info } from "@mui/icons-material";
import KjosNextPage from "component/KjosNextPage";
import CheckRadio from "component/input/CheckRadio";
import { useDispatch, useSelector } from "react-redux";
import { setAlert } from "store/SignalementReducer";
import KjosAlert from "component/KjosAlert";
import scrollToTop from "controller/scrollToTop";
const content = (
  <>
    <Typography variant="body1">Le choix du service pour déposer une plainte est crucial. Pour un crime, contactez la police. Les litiges sur des produits ou services peuvent être résolus via le service client ou une organisation de consommateurs. Pour les violations des droits de l'homme, les ONG spécialisées sont là. Optez pour le moyen adapté à votre situation pour une résolution plus rapide. Soyez précis dans les détails fournis.</Typography>
  </>
);

const brigade = [
  {
    id: 1,
    nom: "BAC N°01 (BRIGADE ANTI CRIMINELLE)",
    adresse: "Constantin - Touguiwondy Matam Conakry - Guinée",
    tel: "(+224) 628 26 72 62",
  },

  {
    id: 2,
    nom: "BCN INTERPOL",
    adresse: "Bd. de Commerce - ManquepasKaloum - BP 113Conakry - Guinée",
    tel: "(+224) 669 05 38 05",
  },

  {
    id: 1,
    nom: "CMIS NUMERO 1",
    adresse: "Constantin - Touguiwondy Matam Conakry - Guinée",
    tel: "(+224) 628 26 72 62",
  },
  {
    id: 1,
    nom: "BAC N°01 (BRIGADE ANTI CRIMINELLE)",
    adresse: "Constantin - Touguiwondy Matam Conakry - Guinée",
    tel: "(+224) 628 26 72 62",
  },
];

const label = (
  <>
    Choix du service{" "}
    <Tooltip title="Choix du service auprès duquel vous souhaitez déposer plainte">
      {" "}
      <Info />{" "}
    </Tooltip>{" "}
  </>
);
export default function PrePlainteService() {
  const navigate = useNavigate();
  const { getData } = useHttp();
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state.signalement);
  const location = useLocation();

  const handleForward = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const signalement_id = location.state?.signalement_id;
    if (formData.get("admin_id") === "" || formData.get("admin_id") === undefined || formData.get("admin_id") === null) {
      dispatch(setAlert({ open: true, message: "Veuillez choisir un agent", color: "red" }));
      scrollToTop();
      return;
    }

    const data = Object.fromEntries(formData);
    const data2 = {
      ...data,
      signalement_id: signalement_id,
    };
    navigate(PRE_PLAINTE_IDENTITE_PATH, { state: { signalement_id: signalement_id, data: JSON.stringify(data), nextPage: true } });
    // navigate(DESCRIPTION_PATH, { state: { typeSign1: input?.typeSign1, nextPage: true } });
  };

  const [commune, setCommune] = useState([]);
  const [loadBrigade, setLoadBrigade] = useState(false);
  const [brigades, setBrigades] = useState([]);
  const [loadbrigadeDetail, setLoadbrigadeDetail] = useState(false);
  const handleOnchange = async (e) => {
    const { name, value } = e.target;
    if (name === "commune_id") {
      setLoadBrigade(true);
      const data = await getData(USER_GET_ADMIN_BY_PERMISSION + `?commune=${value}`);
      if (data?.error) return;
      const brg = data?.data?.map((item) => ({
        id: item?.id,
        nom: `${item?.nom} ${item?.prenom}`,
        adresse: item?.pays + " - " + item?.ville + " - " + item?.commune.libelle,
        tel: item?.contact,
      }));

      
      setBrigades(brg);
      setLoadBrigade(false);
    }
  };

  useEffect(() => {
    getData(USER_COMMUNE_URL).then((data) => {
      const com = data?.data?.map((item) => ({
        label: item.libelle,
        value: item.id,
        id: item.id,
      }));
      
      setCommune(com);
    });

    return () => {
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, []);

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
      >
        <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>
          <KjosHeader>Créer un signalement | ANSSI</KjosHeader>
          <KjosAlert label={alert.message} />

          <HeaderConnecte />
          <form onSubmit={handleForward} style={{ width: "100%", height: "100%" }}>
            {location?.state?.signalement_id && (
              <Typography variant="body2" sx={{ textAlign: "center", background: "linear-gradient(95.08deg, rgba(33, 39, 89, 1) 0%, rgba(74, 144, 226, 1) 100%)", font: "800 24px sans-serif", textDecoration: "underline", "-webkit-background-clip": "text", "-webkit-text-fill-color": "transparent" }}>
                Signalement N°: {location?.state?.signalement_id}
              </Typography>
            )}
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ height: "100%", width: "100%", px: { xs: 1, md: 8 }, py: 8 }} spacing={4}>
              <Rectangle label={label} content={content} />

              {/* Grid */}
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="h6" sx={{ textAlign: "center", fontWeight: "bold" }}>
                    Veuillez sélectionner une autorité compétente avant de poursuivre
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ px: 2 }}>
                  <Select label="Choisir point d'appui" options={commune} name="commune_id" required width="100%" onChange={handleOnchange} /> <br />
                  {/* <Input label="Numéro de téléphone" type="text" name="contact" /> <br /> */}
                  <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2} sx={{ display: { xs: "none", md: "flex" } }}>
                    <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)}>
                      Précédent
                    </ButtonMui>

                    <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                      Suivant
                    </ButtonMui>
                  </Stack>
                </Grid>

                <Grid item xs={12} md={6} sx={{ maxHeight: "15rem" }}>
                  <Stack direction={"column"} spacing={1} sx={{ maxHeight: "15rem", overflow: "auto", ml: "auto", alignItems: "start", py: 4 }}>
                    {loadBrigade ? (
                      <Typography variant="body2" sx={{ textAlign: "center", fontWeight: "bold" }}>
                        Chargement...
                      </Typography>
                    ) : (
                      <>
                        {Array.isArray(brigades) && brigades.length === 0 && (
                          <Typography variant="body2" sx={{ textAlign: "center", fontWeight: "bold" }}>
                            Pas d'agents dans cette commune!
                          </Typography>
                        )}

                        {Array.isArray(brigades) &&
                          brigades.map((brigade, index) => {
                            const content = (
                              <>
                                <Typography variant="h6" sx={{ textAlign: "start", fontWeight: "bold" }}>
                                  {brigade.nom}
                                </Typography>
                                <Typography variant="body2" sx={{ textAlign: "start" }}>
                                  {brigade.adresse} tél. {brigade.tel}{" "}
                                </Typography>
                              </>
                            );

                            const label = <div style={{ width: "100%", padding: "2px 16px", borderRadius: 8, textAlign: "left", font: '500 16px/24px "Poppins-Medium", sans-serif', borderImage: "linear-gradient(180deg, rgba(1, 173, 87, 1) 0%, rgba(254, 0, 0, 1) 100%)", border: "2px solid" }}>{content}</div>;
                            // <Rectangle  content={content} sx={{ width: "100%"}} />
                            return (
                              // <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start", marginBottom: "10px", width: "100%", marginTop: brigades.length > 5 && index === 0 ? "250px" : "0px" }}>
                              //   <CheckRadio key={index} name="admin_id" id={brigade.id} width="100%" required value={brigade.id} label={label} index={index} />
                              // </div>
                              <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
                                <CheckRadio key={index} name="admin_id" id={brigade.id} width="100%" required value={brigade.id} label={label} index={index} />

                                {/* <input type="radio" name="admin_id" id={brigade.id} value={brigade.id} required /> {label} */}
                              </Stack>
                            );
                          })}
                      </>
                    )}
                  </Stack>
                </Grid>

                {/* <Grid item xs={12} md={6} lg={4}>
                  <KjosYMaps />
                </Grid> */}

                <Grid item xs={12} md={6} sx={{ display: { xs: "flex", md: "none" } }}>
                  <Stack direction={"row"} justifyContent={"flex-start"} alignItems={"center"} spacing={2}>
                    <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)}>
                      Précédent
                    </ButtonMui>

                    <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                      Suivant
                    </ButtonMui>
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
          </form>
        </Stack>
      </Box>
      <Footer fixed={false} />
    </>
  );
}
