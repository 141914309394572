import { Box, Button, Container, Grid, IconButton, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "../../hooks/useHttp";
import { USER_HISTORIQUE_URL, USER_SIGNALLEMENT_URL, USER_URL } from "../../config/RoutesUrl";
import KjosSttepper from "../../component/KjosStepper";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import KjosNextPage from "../../component/KjosNextPage";
import KjosMenuDrawer from "../../component/KjosMenuDrawer";
import { BASE_URL_IMAGE, avatar_anonyme } from "../../config/Image";
import KjosInput from "../../component/KjosInput";
import useUser from "../../hooks/useUser";
import KjosSnacBar from "../../component/KjosSnackBar";
import formatDate from "../../controller/formatDate";
import { PhotoCamera } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import getDefaultImage from "../../controller/getDefaultImage";
import KjosInputImage2 from "../../component/KjosInputImage2";
import getFileToTransfert from "../../controller/getFileToTransfert";
import formatPhoneNumber from "../../controller/formatPhoneNumber";
import HeaderConnecte from "../../component/header/HeaderConnecte";
import Input from "../../component/input/Input";
import Footer from "../../component/footer/Footer";
const ProfilEdit = () => {
  const dispatch = useDispatch();
  const { getOneData, getData, putData } = useHttp(); // get data from server
  const location = useLocation();
  const { getUser, updateUser } = useUser();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    autoHideDuration: 5000,
  });
  const navigate = useNavigate();

  const handleOncloseSanackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const formData2 = new FormData();
    
    let contact = formData.get("contact");
    contact = contact?.replace(/[^\d]/g, "");
    let countryCode
    if(formData.get("countryCode")){
      countryCode = formData.get("countryCode").replace(/\+/g, "");
      contact = countryCode + contact
    }
    
   
    formData2.append("nom", formData.get("nom"));
    formData2.append("prenom", formData.get("prenom"));
    formData2.append("contact", contact);
    formData2.append("date_naissance", formData.get("date_naissance") == "" ? getUser("user")?.user?.date_naissance : formData.get("date_naissance"));
    formData2.append("adresse", formData.get("adresse"));
    formData2.append("pays", formData.get("pays"));
    formData2.append("ville", formData.get("ville"));
    formData2.append("role", "user");

    // Si on change de photo
    if (!photoCleanedRef.current && photoRef.current.files[0]) {
      formData2.append("photo", photoRef.current.files[0]);
      formData2.append("photo_changed", "changed");
    }

    // Si on supprime photo
    if (photoCleanedRef.current && !photoRef.current.files[0]) {
      formData2.append("photo", "cleaned");
      formData2.append("photo_changed", "changed");
    }

    // Si photo ne change pas et on supprime pas on garde l'ancienne photo
    if (!photoCleanedRef.current && !photoRef.current.files[0]) {
      formData2.append("photo_changed", "unchanged");
    }

    const token = getUser("user")?.token; // get token
    const config = { headers: { Accept: "application/json", Authorization: `Bearer ${token}` }, "Content-Type": "multipart/form-data" }; // get config

    const editUser = await putData(formData2, USER_URL + "/" + getUser("user")?.user?.id);

    if (editUser?.error) {
      setSnackbar({
        open: true,
        message: "Erreur lors de l'enregistrement",
        severity: "error",
        autoHideDuration: 5000,
      });
    } else {
      updateUser("user", editUser?.data);
      setSnackbar({
        open: true,
        message: "Enregistré avec succès",
        severity: "success",
        autoHideDuration: 5000,
      });

      navigate(-1);
    }
  };

  const photoRef = useRef();
  const photoCleanedRef = useRef(false);

  useEffect(() => {
    getOneData(getUser("user")?.user?.id, USER_URL).then((user) => {
      updateUser("user", user?.data);
    });
  }, [getOneData, updateUser]);

  return (
    <KjosNextPage nextPage={location?.state?.nextPage}>
      <HeaderConnecte />

      <form onSubmit={handleSubmit}>
        <Stack sx={{ bgColor: "#F6F7FA", borderRadius: 5 }}>
          <Grid container>
            {/* les champs */}
            <Grid item xs={12} md={8}>
              <Grid container spacing={4} sx={{ p: { xs: 2, md: 4, lg: 15 } }}>
                <Grid item xs={12} md={6}>
                  <Input
                    label={
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                          },
                        }}
                      >
                        Nom
                      </Typography>
                    }
                    width="100%"
                    type="text"
                    name="nom"
                    defaultValue={getUser("user")?.user?.nom}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input l label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                        },
                      }}
                    >
                      Prénom
                    </Typography>
                  } width="100%" type="text" name="prenom" defaultValue={getUser("user")?.user?.prenom} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                        },
                      }}
                    >
                      Email
                    </Typography>
                  } width="100%" type="email" disabled name="email" defaultValue={getUser("user")?.user?.email} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Input
                    label={
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "16px",
                          },
                        }}
                      >
                        Contact
                      </Typography>
                    }
                    width="100%"
                    type="tel"
                    disabled
                    name="contact"
                    value={phoneNumber === null ? formatPhoneNumber(getUser("user")?.user?.contact) : phoneNumber}
                    onChange={(event) => {
                      setPhoneNumber(formatPhoneNumber(event));
                    }}
                    /* defaultValue={getUser("user")?.user?.contact} */
                    emailPhone={true}
                    nameCode={"countryCode"}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input  label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                        },
                      }}
                    >
                      Date de naissance
                    </Typography>
                  } width="100%" type="date" name="date_naissance" defaultValue={formatDate(getUser("user")?.user?.date_naissance)} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Input  label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                        },
                      }}
                    >
                      Addresse
                    </Typography>
                  }width="100%" type="text" name="adresse" defaultValue={getUser("user")?.user?.adresse} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Input  label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                        },
                      }}
                    >
                    Pays
                    </Typography>
                  } width="100%" type="text" name="pays" defaultValue={getUser("user")?.user?.pays} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Input  label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                        },
                      }}
                    >
                      Ville
                    </Typography>
                  } width="100%" type="text" name="ville" defaultValue={getUser("user")?.user?.ville} />
                </Grid>

                <Grid item xs={12} md={6} >
                  <Button variant="contained" type="submit" color="primary" className="Btn-suivant" sx={{mr: "auto"}}>
                    {" "}
                    Validation{" "}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={4} sx={{ p: { xs: 2, md: 4, lg: 15 } }}>
              {/* Photo utilisateur */}
              <KjosInputImage2 source={getDefaultImage(getUser("user")?.user?.photo)} photoRef={photoRef} defaultImage={avatar_anonyme} photoCleanedRef={photoCleanedRef} />
            </Grid>
          </Grid>

          <KjosSnacBar open={snackbar?.open} message={snackbar?.message} severity={snackbar?.severity} onClose={handleOncloseSanackbar} autoHideDuration={snackbar?.autoHideDuration} />
        </Stack>
      </form>
      <Footer />
    </KjosNextPage>
  );
};

export default ProfilEdit;
