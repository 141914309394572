import { Box, Button as ButtonMui, Container, Grid, Stack, Typography } from "@mui/material";

import KjosHeader from "component/KjosHeader";
import Footer from "component/footer/Footer";
import Rectangle from "component/rectangle/Rectangle";
import HeaderConnecte2 from "../../component/header/HeaderConnecte2";
import Header from "component/header/Header";
import { useEffect, useState } from "react";
import useUser from "hooks/useUser";
import useHttp from "hooks/useHttp";
import { CMS_GET } from "config/RoutesUrl";

const content = (
  <>
    <Typography variant="h5" sx={{ textAlign: "center", fontWeight: "bold" }}>
      {" "}
      POLITIQUE DE CONFIDENTIALITÉ POUR L’APPLICATIONS DE SIGNALEMENT DE l’ANSSI
    </Typography>
    <Typography variant="body1" sx={{ textAlign: "left" }}>
      Introduction <br />
      Nous attachons une grande importance à la protection de la vie privée et des données personnelles de nos utilisateurs. Cette politique de confidentialité décrit la manière dont nous collectons, utilisons et protégeons les informations que vous nous fournissez lorsque vous utilisez notre application de signalement de signaler les actes de cyber criminalité, cyber malveillance et divers incidents techniques relatives à la cyber sécurité. <br />
      Collecte des données personnelles <br />
      Nous collectons des données personnelles lorsque vous utilisez notre application, notamment : Les informations que vous fournissez lorsque vous signalez un acte de corruption, telles que votre nom, votre adresse e-mail et les détails de l'acte de corruption signalé ; <br />
      Les informations que vous fournissez lorsque vous vous inscrivez à notre service de newsletter ou à d'autres communications ; <br />
      Les informations que nous recueillons automatiquement lorsque vous utilisez notre application, telles que votre adresse IP, votre type de navigateur, votre système d'exploitation et les pages que vous consultez sur notre application. Utilisation des données personnelles <br />
      Nous utilisons les données personnelles que nous collectons pour les finalités suivantes: Vous fournir les services que vous avez demandés, tels que le signalement d'actes de cyber criminalité, cyber malveillance et divers incidents techniques relatives à la cyber sécurité et la réception de nos newsletters ou d'autres communications ; <br />
      Analyser l'utilisation de notre application afin d'améliorer son fonctionnement et de vous offrir une meilleure expérience utilisateur ; <br />
      Assurer la sécurité de notre application et prévenir la fraude ou toute autre activité illégale ; Nous conformer à nos obligations légales. <br />
      Divulgation des données personnelles <br />
      Nous ne vendons pas les données personnelles que nous collectons à des tiers. Cependant, nous pouvons divulguer ces données dans les circonstances suivantes : <br />
      À des prestataires de services tiers qui nous aident à fournir nos services, tels que les fournisseurs d'hébergement et les fournisseurs de services de messagerie ; <br />
      À des autorités publiques ou judiciaires si nous sommes légalement tenus de le faire ou si nous pensons de bonne foi que la divulgation est nécessaire pour se conformer à une obligation légale ou pour protéger nos droits, notre propriété ou notre sécurité. <br />
      Sécurité des données personnelles <br />
      Nous prenons des mesures de sécurité appropriées pour protéger les données personnelles que nous collectons contre la perte, l'utilisation abusive et l'accès non autorisé, la divulgation, la modification et la destruction. Toutefois, malgré nos efforts, aucune mesure de sécurité n'est totalement sûre. Conservation des données personnelles <br />
      Nous conservons les données personnelles que nous collectons aussi longtemps que nécessaire pour fournir nos services et pour nous conformer à nos obligations légales. Nous supprimerons les données personnelles lorsque nous n'en avons plus besoin ou si vous nous demandez de le faire. <br />
      Droits des utilisateurs <br />
      Vous avez le droit de demander l'accès, la rectification ou la suppression des données personnelles que nous détenons vous concernant. Vous pouvez également nous demander de limiter le traitement de vos données personnelles ou de les transférer à une autre organisation. Si vous souhaitez exercer l'un de ces droits, veuillez nous contacter à l'adresse e-mail indiquée ci-dessous. <br />
      Modifications de la politique de confidentialité Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment en publiant une version mise à jour sur notre application. Nous vous recommandons de consulter régulièrement cette politique pour vous tenir informé des modifications éventuelles. <br /> <br />
      Contact <br />
      Si vous avez des questions ou des préoccupations concernant notre politique de confidentialité ou notre utilisation de vos données personnelles, veuillez nous contacter à l'adresse e-mail suivante : [support@anssi.gov.gn]. Dernière mise à jour : [16 octobre 2023].
    </Typography>
  </>
);

export default function PolitiqueConfidentialite() {
  const { getUser } = useUser(); //will check if user is authenticated
  const { getData } = useHttp(); // get data from server
  const [cms, setcms] = useState({});
  useEffect(() => {
    getData(CMS_GET).then((data) => {
      if (data?.error) {
        return;
      }
      console.log("footer", data?.data?.footer)
      setcms(data?.data);
    });
  }, [])

  return (
    <>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 0,
          px: 0,
          width: "100%",
        }}
        className="content"
      >
        <Stack direction={"column"} sx={{ p: 0, width: "100%" }}>
          <KjosHeader>Politique de confidentialité | ANSSI</KjosHeader>
          {getUser("user") ? <HeaderConnecte2 /> : <Header />}

          <form style={{ width: "100%", height: "100%" }}>
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} sx={{ height: "100%", width: "100%", p: 8 }} spacing={4}>
              <Rectangle content={ cms?.footer?.politique_content ? <div dangerouslySetInnerHTML={{ __html: cms?.footer?.politique_content }}></div>: content} />
            </Stack>
          </form>
        </Stack>
      </Box>
      <Footer fixed={false} />
    </>
  );
}
