import { useState } from "react";
import useLocalSorage from "./useLocalSorage";
import { useEffect } from "react";
import { useCallback } from "react";
import { avatar_anonyme_boy } from "../config/Image";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../store/AuthenticationReducer";

function useUser() {
  const [getLocalStorage, removeLocalStorage, setLocalStorage] = useLocalSorage();
  const dispatch = useDispatch()

  const updateUser = useCallback( 
    (name, data) => {
      // Get the existing data
      const localStorageData = getLocalStorage(name);
      var localUser = localStorageData?.user;
      // If no existing data, create an array
      // Otherwise, convert the localStorage string to an array

      //  loaclUser = loaclUser ? JSON.parse(loaclUser) : {};

      Object.keys(data).forEach((key) => {
        // Add new data to localStorage Array
        if (key != "email") {
          localUser[key] = data[key];
        }
      });

      dispatch(setUser(localUser)); //update user state
      localStorageData.user = localUser; // Save back to localStorage
      setLocalStorage(name, localStorageData);
    },
    [getLocalStorage, dispatch, setLocalStorage]
  );

  const getUser = useCallback(
    (name) => {
      const localStorageData = getLocalStorage(name);
      var localUser = localStorageData?.user; //get user data
      //If user photo null set default photo
      const photo = localUser?.photo ?? avatar_anonyme_boy;
      localStorageData && localStorageData.user && (localStorageData.user.photo = photo);
      dispatch(setUser(localStorageData?.user)); //update user state
      setLocalStorage(name, localStorageData);
      return localStorageData;
    },
    [getLocalStorage, dispatch, setLocalStorage]

    
  );

  const getUserToken = useCallback(
    (name) => {
      const localStorageData = getLocalStorage(name);
      var localUser = localStorageData?.user; //get user data
      //If user photo null set default photo
      const photo = localUser?.photo ?? avatar_anonyme_boy;
      localStorageData && localStorageData.user && (localStorageData.user.photo = photo);
      dispatch(setUser(localStorageData?.user)); //update user state
      setLocalStorage(name, localStorageData);
      return localStorageData?.token;
    },
    [getLocalStorage, dispatch, setLocalStorage]

    
  );

  const getUserHabilitation = useCallback(
    (name) => {
      const localStorageData = getLocalStorage(name);
      return localStorageData?.habiletes;
    },
    [getLocalStorage]

    
  );

  return {getUser, updateUser, getUserToken, getUserHabilitation };
}

export default useUser;
