import { Paper, Typography } from "@mui/material";

export default function Rectangle(props) {
   const { label, content, sx } = props
   return (
      <Paper sx={{ position: "relative", borderRadius: 4, border: "2px solid #8e9198", p: 2, ...sx }}>
         {content}
      <Typography variant="body1" sx={{ position: "absolute", top: 0, left: { xs: "50%", md: "12%" }, transform: "translate(-50%, -50%)", bgcolor: "white", p: 0 }}>
        {label}
      </Typography>
    </Paper>
   );
}