import { Box, Button, Container, Grid, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "../../hooks/useHttp";
import { USER_HISTORIQUE_URL, USER_SIGNALLEMENT_URL } from "../../config/RoutesUrl";
import KjosSttepper from "../../component/KjosStepper";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import KjosNextPage from "../../component/KjosNextPage";
import KjosMenuDrawer from "../../component/KjosMenuDrawer";
import { BASE_URL_IMAGE } from "../../config/Image";
import { DASHBOARD_PATH, PROFIL_EDIT_PATH } from "../../config/PathUrl";
import useUser from "../../hooks/useUser";
import getDefaultImage from "../../controller/getDefaultImage";
import getCurrentDate from "../../controller/getCurrentDate";
import HeaderConnecte from "../../component/header/HeaderConnecte";
import Footer from "../../component/footer/Footer";
const Profil = () => {
  const dispatch = useDispatch();
  const { getOneData, getData } = useHttp(); // get data from server
  const location = useLocation();
  const navigate = useNavigate();
  const { getUser } = useUser();
  console.log(getUser("user")?.user?.photo)
  return (
    <>
      <HeaderConnecte />
        <Box component={"main"} sx={{ flexGrow: 1, p: 5, backgroundColor: "#F6F7FA", borderRadius: "10px" }}>
          <Container maxWidth={"xl"} sx={{ backgroundColor: "white", p: 5, borderRadius: 2, boxShadow: "0px 0px 5px grey" }}>
            <Grid container spacing={2}>
              <Grid item md={12} lg={8} sx={{ p: 4 }}>
                <Stack direction={"row"} spacing={2}>
                <Button onClick={() => navigate(PROFIL_EDIT_PATH, { state: { id: getUser("user")?.user?.id, nextPage: true } })}>Modification</Button>
                </Stack>
              </Grid>
              <Grid item md={12} lg={8} sx={{ p: 4 }}>
                <Stack
                  spacing={2}
                  sx={{
                    p: 4,
                    backgroundColor: "#F6F7FA",
                    borderRadius: "10px",
                    boxShadow: "0px 0px 5px grey",
                  }}
                  direction={"column"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="h6">Détails</Typography>

                  <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body">Nom</Typography>
                    <Typography variant="body">{getUser("user")?.user?.nom}</Typography>
                  </Stack>

                  <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body">Prenom</Typography>
                    <Typography variant="body">{getUser("user")?.user?.prenom}</Typography>
                  </Stack>

                  {/* <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body">Date de naissance</Typography>
                    <Typography variant="body">{getCurrentDate("d-m-y", getUser("user")?.user?.date_naissance)}</Typography>
                  </Stack> */}

                  <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body">Email</Typography>
                    <Typography variant="body">{getUser("user")?.user?.email}</Typography>
                  </Stack>

                  <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body">Contact</Typography>
                    <Typography variant="body">{getUser("user")?.user?.contact}</Typography>
                  </Stack>

                  <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
                    <Typography variant="body">Adresse</Typography>
                    <Typography variant="body">{getUser("user")?.user?.adresse}</Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={12} lg={4} sx={{ p: 4,}}>
              <Stack sx={{ backgroundColor: "#F6F7FA", borderRadius: "10px", boxShadow: "0px 0px 5px grey", p: 1, width: "11rem" }}>
                <img className="img-fluid" src={getDefaultImage(getUser("user")?.user?.photo)} alt="user" style={{ width: "10rem", aspectRatio: "1/1", objectFit: "cover" }} />
              </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Footer />
      
    </>
  );
};

export default Profil;
