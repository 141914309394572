import { Box, Button as ButtonMui, Container, Grid, Stack, Switch, Typography, useMediaQuery } from "@mui/material";
import { FANCY_BLUE, main_blue, main_gray } from "../../config/Color";
import Logo from "../../component/logo/Logo";
import Input from "../../component/input/Input";
import { Link, useNavigate } from "react-router-dom";
import { FORGET_PASSWORD_URL } from "../../config/RoutesUrl";
import Button from "../../component/buttons/Button";
// import ButtonSmall from "../../component/buttons/ButtonSmal";
import useIsAuthenticate from "../../hooks/useIsAuthenticate";
import { useDispatch, useSelector } from "react-redux";
import useResetPassword from "../../hooks/useResetPassword";
import { useEffect } from "react";
import { setAuth } from "../../store/AuthenticationReducer";
import { setAlert } from "../../store/SignalementReducer";
import KjosAlert from "../../component/KjosAlert";
import { SIGNUP_PATH } from "config/PathUrl";
import Header2 from "../../component/header/Header2";
import { RESET_PASSWORD_CONFIRM_CODE_PATH } from "../../config/PathUrl";
import detectEmailOrNumber from "controller/detectEmailOrNumber";

export default function SignInPasswordForget() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const { auth, loading, error } = useSelector((state) => state.authentication);
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const dispatch = useDispatch();
  const { alert } = useSelector((state) => state.signalement);
  const navigate = useNavigate();
  const { resetPassword } = useResetPassword();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get("email").replace(/\s/g, "");
    const type = detectEmailOrNumber(email)
    if(!type){
      dispatch(setAlert({
        open: true,
        message: "Email ou numéro invalide",
        color: "rgba(254, 0, 0, 1)",
      }))
      
    }
    const data = {
      email: email,
      role: "user",
      type: type,
    };
    const result = await resetPassword(data);
    if(result?.error){
      dispatch(setAlert({
        open: true,
        message: result?.message,
        color: "rgba(254, 0, 0, 1)",
      }))
    }
    else {
    navigate(RESET_PASSWORD_CONFIRM_CODE_PATH, { state: {  email: email, nextPage: true } });
    }
  }

  useEffect(() => {
    return () => {
      dispatch(setAuth({}));
      dispatch(
        setAlert({
          open: false,
          message: "",
        })
      );
    };
  }, [dispatch]);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <KjosAlert label={alert.message} />
      <Header2 />

      <form onSubmit={handleFormSubmit} style={{ height: "100%" }}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={0} md={3}></Grid>
          <Grid item xs={"12"} md={6} sx={{ height: { md: "100%" } }}>
            <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} p={6} sx={{ height: { md: "100%" } }}>
              <Box sx={{ marginY: { md: "auto" } }}>
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: {
                      xs: "2rem",
                      sm: "3rem",
                      md: "4rem",
                      color: main_blue,
                    },
                  }}
                >
                  Renseignez votre mail ou numéro de téléphone{" "}
                </Typography>
                <Typography variant="body2" sx={{ color: main_blue }}>
                  Vous recevrez un code sur le mail ou le numéro de téléphone pour continuer
                </Typography>

                <br />

                <Input
                  emailPhone={true}
                  placeholder="Exemple@gmail.com"
                  label={
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "16px",
                        },
                      }}
                    >
                      Email ou Téléphone
                    </Typography>
                  }
                  type="text"
                  width="100%"
                  // error={error && auth?.email && error}
                  name="email"
                />
              </Box>

              {/* <ButtonSmall title="Suivant" sx={{alignSelf:"flex-start"}} type="submit" /> */}
              <Stack direction="row" spacing={2} >
              <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} onClick={() => navigate(-1)} >
                Retour
              </ButtonMui>
              
              <ButtonMui className="Btn-suivant" variant="contained" sx={{ alignSelf: "flex-start" }} type="submit">
                Suivant
              </ButtonMui>
              </Stack>


              {/* Copyright */}
              <Typography variant="body1" sx={{ color: main_blue, marginTop: "auto", textAlign: "center" }}>
                Copyright @ ANSSI 2023
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={0} md={3}></Grid>
        </Grid>
      </form>
    </Box>
  );
}
