import { IconButton, Stack, Typography } from "@mui/material";
import "./input.css";
import { RemoveRedEye } from "@mui/icons-material";
import { useState } from "react";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Email } from "@mui/icons-material";
import { Phone } from "@mui/icons-material";


export default function Select(prop) {
  const {  width, type, required, value, defaultValue, onChange,  disabled, style, label, error, name, id, inputRef, options } = prop;


  return (
    <Stack direction={"column"} justifyContent={"center"} alignItems={"flex-start"} sx={{ width: "100%" }}>
      <div className="frame-1000002056-2" style={{ ...style, width: width }}>
        <select
          style={style}
          className={"_00-00-00-00-00-2"}
          // value={props?.defaultValue?.id}
          value={value ?? defaultValue}
          disabled={disabled}
          id={id}
          name={name}
          required={required}
          type={type}
          onChange={onChange}
        >
          {/* <option selected value={props?.defaultValue?.id}> {props?.defaultValue?.label} </option> */}
          {options?.map((option, index) => (
            <option key={index} value={option?.id}>
              {option?.label}
            </option>
          ))}
        </select>
        
        <div className="frame-1000002030-2">
          <div className="email-ou-num-ro-de-t-l-phone-2"> {label} </div>
        </div>
      </div>

      <Typography variant="body1" sx={{ color: "red" }}>
        {" "}
        {error}{" "}
      </Typography>
    </Stack>
  );
}
